import Image from './Image'
import {PropsWithChildren, PropsWithClassName} from '../utils'
import {
  cloudflareLogo,
  cloudRunLogo,
  dartLogo,
  dockerLogo,
  firebaseLogo,
  flutterLogo,
  framerLogo,
  jsLogo,
  kotlinLogo,
  ktorLogo,
  leanCloudLogo,
  mongodbLogo,
  muiLogo,
  netlifyLogo,
  nextjsLogo,
  nginxLogo,
  pythonLogo,
  reactLogo,
  rustLogo,
  tailwindLogo,
  tsLogo,
  vercelLogo,
} from '../icons/icons'
import { h } from 'preact'

export function TechnologyTag(props: PropsWithClassName<{ data: TechnologyTagData, spacing?: string }>) {
  return <a href={props.data.href} className={`flex items-center whitespace-nowrap cursor-alias active:scale-95 active:brightness-90 ${props.spacing ?? 'px-4 py-2 mt-2'} rounded-full ${props.className ?? ''}`} target={'_blank'}
            rel={'noreferrer'}>
    <div className={'w-6 mr-2'}>
      <Image src={props.data.image}/>
    </div>
    {props.data.name}
  </a>
}

function TechnologyStackCard(props: PropsWithClassName<PropsWithChildren<{ title: string }>>) {
  return <div className={`flex flex-col items-center border-2 rounded-xl p-2 pt-1 ${props.className ?? ''}`}>
    <p className={'text-xl whitespace-nowrap'}>{props.title}</p>
    {props.children}
  </div>
}

export type TechnologyCardData = {
  title: string,
  tags: TechnologyTagData[],
}

export type TechnologyTagData = {
  name: string,
  image: string,
  href: string,
}

export function TechnologyCards(props: PropsWithClassName<{ data: TechnologyCardData[], cardClassName: string, tagClassName: string, tagSpacing?: string }>) {
  return <div className={`flex px-3 justify-start overflow-x-auto max-w-full ${props.className ?? ''}`}>
    {props.data.map(cardData => <TechnologyStackCard key={cardData.title} title={cardData.title} className={`mx-3 ${props.cardClassName ?? ''}`}>
      {cardData.tags.map(tagData => <TechnologyTag key={tagData.name} data={tagData} className={props.tagClassName} spacing={props.tagSpacing}/>)}
    </TechnologyStackCard>)}
  </div>
}

export const TechnologyTags = {
  kotlin: {
    name: 'Kotlin',
    image: kotlinLogo,
    href: 'https://kotlinlang.org/',
  },
  ktor: {
    name: 'Ktor',
    image: ktorLogo,
    href: 'https://ktor.io/',
  },
  mongoDB: {
    name: 'MongoDB',
    image: mongodbLogo,
    href: 'https://www.mongodb.com/',
  },
  mongoDBAtlas: {
    name: 'MongoDB Atlas',
    image: mongodbLogo,
    href: 'https://www.mongodb.com/cloud/atlas/serverless',
  },
  docker: {
    name: 'Docker',
    image: dockerLogo,
    href: 'https://www.docker.com/',
  },
  dart: {
    name: 'Dart',
    image: dartLogo,
    href: 'https://dart.dev/',
  },
  flutter: {
    name: 'Flutter',
    image: flutterLogo,
    href: 'https://flutter.dev/',
  },
  firebase: {
    name: 'Firebase',
    image: firebaseLogo,
    href: 'https://firebase.google.com/',
  },
  firebaseAuth: {
    name: 'Firebase Auth',
    image: firebaseLogo,
    href: 'https://firebase.google.com/docs/auth',
  },
  js: {
    name: 'JavaScript',
    image: jsLogo,
    href: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
  },
  react: {
    name: 'React',
    image: reactLogo,
    href: 'https://reactjs.org/',
  },
  netlify: {
    name: 'Netlify',
    image: netlifyLogo,
    href: 'https://www.netlify.com/',
  },
  ts: {
    name: 'TypeScript',
    image: tsLogo,
    href: 'https://www.typescriptlang.org/',
  },
  framerMotion: {
    name: 'Framer Motion',
    image: framerLogo,
    href: 'https://www.framer.com/motion/',
  },
  leanCloud: {
    name: 'LeanCloud',
    image: leanCloudLogo,
    href: 'https://leancloud.app/',
  },
  cloudRun: {
    name: 'Cloud Run',
    image: cloudRunLogo,
    href: 'https://cloud.google.com/run',
  },
  python: {
    name: 'Python',
    image: pythonLogo,
    href: 'https://www.python.org/',
  },
  mui: {
    name: 'MUI',
    image: muiLogo,
    href: 'https://mui.com/',
  },
  cloudflare: {
    name: 'Cloudflare',
    image: cloudflareLogo,
    href: 'https://www.cloudflare.com/',
  },
  r2: {
    name: 'Cloudflare R2',
    image: cloudflareLogo,
    href: 'https://blog.cloudflare.com/introducing-r2-object-storage/',
  },
  nextjs: {
    name: 'Nextjs',
    image: nextjsLogo,
    href: 'https://nextjs.org/',
  },
  nextjsAPI: {
    name: 'Nextjs API',
    image: nextjsLogo,
    href: 'https://nextjs.org/docs/api-routes/introduction',
  },
  vercel: {
    name: 'Vercel',
    image: vercelLogo,
    href: 'https://vercel.com/home',
  },
  tailwindCSS: {
    name: 'Tailwind CSS',
    image: tailwindLogo,
    href: 'https://tailwindcss.com/',
  },
  nginx: {
    name: 'Nginx',
    image: nginxLogo,
    href: 'https://www.nginx.com/',
  },
  rust: {
    name: 'Rust',
    image: rustLogo,
    href: 'https://www.rust-lang.org/',
  },
}