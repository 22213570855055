import ProjectCard from '../ProjectCard'
import rpiMonitorLogo from './rpiMonitorIcon.png'
import CommunicationDiagram from './CommunicationDiagram'
import Button from '../../../../components/Button'
import {Media} from '../../../../media'
import {PropsWithClassName} from '../../../../utils'
import Title from '../../../../components/Title'
import FeaturedNumbers from '../../../../components/FeaturedNumbers'
import Icon from '@mdi/react'
import {mdiNumeric1CircleOutline, mdiNumeric2CircleOutline, mdiNumeric3CircleOutline} from '@mdi/js'
import {TechnologyCards, TechnologyTags} from '../../../../components/TechnologyCard'
import { h } from 'preact'


function ButtonBar(props: PropsWithClassName<{}>) {
  return <div className={props.className}>
    <Button href={'https://github.com/PegasisForever/raspi_monitor_app'} className={'bg-[#2b66b8] text-white'}>
      Github (App)
    </Button>
    <Button href={'https://github.com/PegasisForever/raspi_monitor'} className={'bg-[#2b66b8] text-white'}>
      Github (Agent)
    </Button>
    <Button href={'https://play.google.com/store/apps/details?id=site.pegasis.raspi_monitor_app'} className={'bg-[#2b66b8] text-white'}>
      Google Play
    </Button>
  </div>
}

const featuredNumbers = [
  {
    number: '400kB',
    text: 'Agent size',
  },
  {
    number: '0.01s',
    text: 'Time takes for the agent to retrieve system status',
  },
]

function DesktopProjectCardContent() {
  return <div className={'flex flex-col items-start p-6'}>
    <Title imageClassName={'w-24 -m-2'} logo={rpiMonitorLogo} title={'Raspberry Pi Monitor'} subTitle={'Zero dependency remote system monitor for linux machines.'}/>
    <CommunicationDiagram/>
    <div className={'-mt-16'}>
      <FeaturedNumbers data={featuredNumbers} />
      <ButtonBar className={'flex mt-4 -ml-2 -mb-2'}/>
    </div>
  </div>
}

function MobileProjectCardContent() {
  return <div className={'flex flex-col items-center'}>
    <Title className={'mt-6'} imageClassName={'w-24 -m-2'} logo={rpiMonitorLogo} title={'Raspberry Pi Monitor'}
           subTitle={'Zero dependency remote system monitor for linux machines.'}/>

    <div className={'grid grid-cols-[auto_auto] place-items-center gap-x-2 gap-y-1 mt-6'}>
      <Icon path={mdiNumeric1CircleOutline} size={'2.5rem'}/>
      <span className={'w-full'}>Downloads the latest agent from the server.</span>
      <Icon path={mdiNumeric2CircleOutline} size={'2.5rem'}/>
      <span className={'w-full'}>Uploads the agent to <code>/tmp</code> using sftp.</span>
      <Icon path={mdiNumeric3CircleOutline} size={'2.5rem'}/>
      <span className={'w-full'}>Retrieves status from the agent using ssh.</span>
    </div>

    <FeaturedNumbers data={featuredNumbers} className={'mt-6'}/>

    <TechnologyCards data={[
      {title: 'Server', tags: [TechnologyTags.nginx, TechnologyTags.cloudflare]},
      {title: 'Mobile', tags: [TechnologyTags.dart, TechnologyTags.flutter]},
      {title: 'Agent', tags: [TechnologyTags.rust]},
    ]} className={'mt-6'} cardClassName={'border-gray-350 mx-3'} tagClassName={'bg-black bg-opacity-10'}/>
    <ButtonBar className={'flex flex-wrap my-4 justify-center'}/>
  </div>
}

export default function RpiMonitorProjectCard() {
  return <ProjectCard>
    <Media greaterThanOrEqual={'xl'}>
      <DesktopProjectCardContent/>
    </Media>
    <Media lessThan={'xl'}>
      <MobileProjectCardContent/>
    </Media>
  </ProjectCard>
}