import Image from '../../../../components/Image'
import owGuessrLogo from './owGuessrLogo.png'
import ProjectCard from '../ProjectCard'
import LazyVideo from '../../../../components/LazyVideo'
import videoFirstFrame from './videoFirstFrame.jpg'
import Button from '../../../../components/Button'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import {h} from 'preact'

function Title() {
  return <div className={'flex items-center'}>
    <p className={'w-14 inline-block flex-none'}>
      <Image src={owGuessrLogo} className={'rounded shadow'}/>
    </p>
    <div className={'ml-2'}>
      <p className={'text-2xl font-bold'}>OW Guessr</p>
      <p className={'text-gray-600'}>Guess Overwatch maps from screenshots.</p>
    </div>
  </div>
}

export default function OWGuessrProjectCard() {
  return <ProjectCard className={'p-6'}>
    <Title/>
    <div className={'flex items-center'}>
      <TechnologyTag data={TechnologyTags.js} className={'bg-gray-200'} spacing={'px-4 py-2 mr-2 mt-2'}/>
      <TechnologyTag data={TechnologyTags.react} className={'bg-gray-200'} spacing={'px-4 py-2 mr-2 mt-2'}/>
      <TechnologyTag data={TechnologyTags.kotlin} className={'bg-gray-200'} spacing={'px-4 py-2 mr-2 mt-2'}/>
      <TechnologyTag data={TechnologyTags.cloudRun} className={'bg-gray-200'} spacing={'px-4 py-2 mr-2 mt-2'}/>
    </div>
    <LazyVideo w={950} h={630} src={'/videos/owguessr.mp4'} loadingImage={videoFirstFrame} className={'mt-2 rounded overflow-hidden shadow'}/>
    <div className={'flex mt-4 -ml-2 -mb-2'}>
      <Button href={'https://owguessr.pegas.is/'} className={'bg-[#335b9a] text-white'}>
        Website
      </Button>
    </div>
  </ProjectCard>
}