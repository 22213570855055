import ProjectCard from '../ProjectCard'
import astLight from './ASTLight.jpg'
import Image from '../../../../components/Image'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import Button from '../../../../components/Button'
import { h } from 'preact'

export default function TyporaParserCard() {
  return <ProjectCard>
    <div className={'absolute left-[58%] top-0 w-[42%] min-w-[200px]'}>
      <Image priority className={'opacity-80'} src={astLight}/>
    </div>

    <div className={'p-6 pr-0 bg-yellow-40 flex flex-col items-start max-w-[60%] opacity-99 relative'}>
      <p className={'text-4xl'}>
        Typora Parser
      </p>
      <p className={'text-gray-600'}>
        Convert Typora flavoured markdown to HTML.
      </p>
      <TechnologyTag data={TechnologyTags.ts} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2'}/>

      <p className={'mt-4'}>
        <a href={'https://typora.io/'} className={'underline'}>Typora</a>{' '}
        is a popular (I&apos;d say it&apos;s the best){' '}
        <abbr title={'what you see is what you get'}>WYSIWYG</abbr>{' '}
        markdown editor. However it is proprietary and doesn&apos;t provide a command line export tool.{' '}
        This project reverse engineers Typora&apos;s logic and creates an open source library for processing Typora flavoured markdown.
      </p>

      <div className={'flex mt-4 -ml-2 -mb-2'}>
        <Button href={'https://github.com/PegasisForever/typora-parser'} className={'bg-[#2b66b8] text-white'}>
          Github
        </Button>
        <Button href={'https://www.npmjs.com/package/typora-parser'} className={'bg-[#2b66b8] text-white'}>
          npm
        </Button>
      </div>

    </div>
  </ProjectCard>
}