import { h } from 'preact'
import {finishColors, startColors} from '../utils'
import {forwardRef} from 'preact/compat'

const Divider = forwardRef<HTMLDivElement, { text: string, gradientColorI: number, spacing?: string, id?: string }>((props, ref) => {
  return <div ref={ref} className={`relative w-[calc(100vw-4rem)] sm:w-full max-w-[46rem] xl:max-w-screen-2xl ${props.spacing ?? 'mt-8'}`}>
    <div className={'bg-gray-300 h-0.5 w-full absolute vertical-absolute-center'}/>
    <div className={'bg-gray-50 px-4 absolute vertical-absolute-center horizontal-absolute-center z-10'}>
      <span id={props.id} className={'gradient-text text-4xl font-bold italic'}
         style={{
           backgroundImage: `linear-gradient(to right, ${startColors[props.gradientColorI]} 0%, ${finishColors[props.gradientColorI]} 100%)`,
         }}>
        {props.text}
      </span>
    </div>
  </div>
})

export default Divider