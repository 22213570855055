import {atom, useAtom} from 'jotai'
import {h, RefObject} from 'preact'
import Divider from '../../../components/Divider'
import {useEffect, useRef} from 'preact/compat'

const serversTitleRefState = atom<RefObject<HTMLDivElement>>({current: null})

export {serversTitleRefState}

export default function ServersSection() {
  const titleRef = useRef<HTMLDivElement>(null)
  const [,setTitleRef] = useAtom(serversTitleRefState)
  useEffect(() => {
    setTitleRef(titleRef)
  }, [titleRef, setTitleRef])

  return <div className={'sm:px-8 md:px-16 2xl:pl-80 w-full flex flex-col items-center'}>
    <Divider id={'servers'} ref={titleRef} text={'Servers'} spacing={'mt-16'} gradientColorI={3}/>
    <div className={'mt-16'}>
      <p>
        I can install linux manually using command line with LUKS full disk encryption and LVM{' '}
        (<a href={'https://notes.pegas.is/Arch%20Install%20Notes/'} className={'underline'}>Guide</a>)
      </p>
      <p>I&apos;ve daily driven Linux for over two years</p>
      <p>Experience with using and administrating arch linux and debian</p>
      <p>Experience with GCP, digital ocean and vultr</p>
      <p>Familiar with docker</p>
      <p>What else do you want? :P</p>
    </div>
  </div>
}