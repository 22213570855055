import {Fragment, h} from 'preact'
import {PropsWithChildren} from '../utils'

export function NoSSR(props: PropsWithChildren<{}>) {
  if (typeof window === 'undefined') {
    return null
  } else {
    return <Fragment>
      {props.children}
    </Fragment>
  }
}