import p1 from './1.jpg'
import p2 from './2.jpg'
import p3 from './3.jpg'
import p4 from './4.jpg'
import p5 from './5.jpg'
import p6 from './6.jpg'
import p7 from './7.jpg'
import p8 from './8.jpg'
import p9 from './9.jpg'
import p10 from './10.jpg'

const profilePictures = [
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
]

export default profilePictures