import TeachAssistProjectCard from '../projects/ta/TeachAssistProjectCard'
import PunoProjectCard from '../projects/puno/PunoProjectCard'
import GrokProjectCard from '../projects/grok/GrokProjectCard'
import RpiMonitorProjectCard from '../projects/rpiMonitor/RpiMonitorProjectCard'
import Decide4MeProjectCard from '../projects/decide4me/Decide4MeProjectCard'
import PegaDriveProjectCard from '../projects/pegaDrive/PegaDriveProjectCard'
import DistributionCalculatorProjectCard from '../projects/distributionCalculator/DistributionCalculatorProjectCard'
import OWGuessrProjectCard from '../projects/owGuessr/owGuessr'
import {useLayoutEffect, useRef, useState} from 'preact/compat'
import {m} from 'framer-motion'
import {mdiChevronDown} from '@mdi/js'
import Icon from '@mdi/react'
import {NoSSR} from '../../../components/NoSSR'
import {Fragment, h} from 'preact'
import {useWindowSize} from 'react-use'

function ExpandableSection(props: { expand: boolean }) {
  const [height, setHeight] = useState<number>(0)
  const windowSize = useWindowSize()
  const ref = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (props.expand) {
      setHeight(0)
    } else {
      setHeight(ref.current!.clientHeight)
    }
  }, [props.expand, windowSize])


  return <m.div className={'w-full sm:w-[calc(100%+2rem)] max-w-[calc(1536px+2rem)] relative overflow-x-visible overflow-y-hidden mt-4'}
                animate={{
                  height: `${height}px`,
                }}
                transition={{type: 'tween', duration: 0.5}}>
    <div ref={ref}
         className={'grid justify-items-center grid-cols-1 xl:grid-cols-2 w-full max-w-[48rem] xl:max-w-[calc(1536px+2rem)] gap-x-16 absolute horizontal-absolute-center bottom-0 gap-y-8 md:gap-y-16 pt-4 pb-8 sm:px-4 overflow-x-visible'}>
      <Decide4MeProjectCard/>
      <PegaDriveProjectCard/>
      <DistributionCalculatorProjectCard/>
      <OWGuessrProjectCard/>
    </div>
  </m.div>
}

function ShowMoreButton() {
  const [isShowMore, setShowMore] = useState(true)

  return <Fragment>
    <button
      className={'bg-gray-200 hover:bg-gray-300 px-4 py-2 mt-8 rounded flex items-center relative active:scale-95'}
      onClick={() => setShowMore(!isShowMore)}>
      <span className={'invisible'}>
        Show More
      </span>
      <span className={'absolute left-4 vertical-absolute-center '}>
        {isShowMore ? 'Show More' : 'Show Less'}
      </span>
      <m.div
        animate={{transform: isShowMore ? 'rotate(0deg)' : 'rotate(180deg)'}}
        transition={{type: 'spring', bounce: 0}}>
        <Icon
          path={mdiChevronDown}
          size={'2rem'}/>
      </m.div>
    </button>
    <NoSSR>
      <ExpandableSection expand={isShowMore}/>
    </NoSSR>
  </Fragment>
}

export default function ProjectsSection() {
  return <div className={'sm:px-8 md:px-16 2xl:pl-80 mt-64 w-full flex flex-col items-center'}>
    <div id={'apps_websites'} className={'grid grid-cols-1 max-w-[46rem] xl:max-w-screen-2xl gap-y-8 md:gap-y-16'}>
      <TeachAssistProjectCard/>
      <PunoProjectCard/>
      <GrokProjectCard/>
      <RpiMonitorProjectCard/>
    </div>

    <ShowMoreButton/>
  </div>
}

// todo wavy background like https://loading.io/
// different gradient background based on section