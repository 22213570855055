import {h} from 'preact'
import {forwardRef} from 'preact/compat'
import {PropsWithChildren} from '../../../utils'

const ProjectCard = forwardRef<HTMLDivElement, PropsWithChildren<{ className?: string, id?: string }>>((props, ref) => {
  return <div ref={ref} id={props.id} className={`opacity-99 bg-white w-full sm:rounded-md shadow-lg overflow-hidden relative w-full ${props.className ?? ''}`}>
    {props.children}
  </div>
})

export default ProjectCard
