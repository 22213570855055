import ProjectCard from '../ProjectCard'
import Image from '../../../../components/Image'
import taIcon from './taIcon.png'
import {atom, useAtom} from 'jotai'
import FeaturesGrid from './FeaturesGrid'
import CardCarousel from './CardCarousel'
import Button from '../../../../components/Button'
import {Media} from '../../../../media'
import {TechnologyCards, TechnologyTags} from '../../../../components/TechnologyCard'
import {PropsWithClassName} from '../../../../utils'
import FeaturedNumbers from '../../../../components/FeaturedNumbers'
import {Fragment, h, RefObject} from 'preact'
import {useEffect, useRef} from 'preact/compat'

const technologyCardsData = [
  {title: 'Server', tags: [TechnologyTags.kotlin, TechnologyTags.ktor, TechnologyTags.mongoDB, TechnologyTags.docker]},
  {title: 'Mobile', tags: [TechnologyTags.dart, TechnologyTags.flutter, TechnologyTags.firebase]},
  {title: 'Website', tags: [TechnologyTags.js, TechnologyTags.react, TechnologyTags.firebase]},
]

function Title(props: PropsWithClassName<{}>) {
  return <div className={`flex items-center flex-col xs:flex-row ${props.className ?? ''}`}>
    <p className={'w-24 -m-2 inline-block'}>
      <Image priority src={taIcon}/>
    </p>
    <div className={'mt-2 xs:mt-0 xs:ml-2 text-center xs:text-left'}>
      <p className={'text-gray-600 text-lg xs:text-base'}>Unofficial</p>
      <p className={'text-3xl xs:text-4xl'}>YRDSB Teach Assist</p>
    </div>
  </div>
}

function Introduction(props: PropsWithClassName<{}>) {
  return <p className={props.className}>
    My high school school board (YRDSB) uses a website called &quot;Teach Assist&quot; to allow students to check their marks online.
    The website was developed more than a decade ago, resulting in poor user experience.
    I decided to give it a revamp using modern technologies.
  </p>
}

const featuredNumbers = [
  {
    number: '4000+',
    text: 'Peak daily active users',
  },
  {
    number: '5 Million+',
    text: 'Requests served',
  },
  {
    number: <Fragment>Better than Facebook <sup className={'text-base'}>TM</sup></Fragment>,
    text: 'Annual uptime',
  },
]

function ButtonBar(props: PropsWithClassName<{}>) {
  return <div className={props.className}>
    <Button href={'https://github.com/PegasisForever/YRDSB-Teach-Assist-Mobile'} className={'bg-[#0B78D0] text-white'}>
      Find Out More on Github
    </Button>
    <Button href={'https://play.google.com/store/apps/details?id=site.pegasis.yrdsb.ta'} className={'bg-[#9cd3ff]'}>
      Google Play
    </Button>
    <Button href={'https://apps.apple.com/us/app/yrdsb-teach-assist/id1483082868?ls=1'} className={'bg-[#9cd3ff]'}>
      App Store
    </Button>
    <Button href={'https://ta-yrdsb.web.app/'} className={'bg-[#9cd3ff]'}>
      Website
    </Button>
  </div>
}

function DesktopProjectCardContent() {
  return <div className={'flex flex-col items-start p-6'}>
    <CardCarousel className={'absolute right-[calc(40%-38rem)] vertical-absolute-center opacity-99'}/>
    <div
      className={'absolute left-[-7.5rem] vertical-absolute-center w-[max(calc(6rem+50%),750px)] h-[300%] bg-black opacity-10 transform origin-center-right rotate-[-20deg]'}/>
    <Title className={'opacity-99'}/>
    <div className={'opacity-99'}>
      <Introduction className={'w-[40%] mt-6'}/>
      <FeaturesGrid className={'w-[60%] mt-6'}/>
      <FeaturedNumbers data={featuredNumbers} className={'mt-6'}/>
    </div>
    <TechnologyCards data={technologyCardsData} className={'mt-6 -ml-6 opacity-99'} cardClassName={'border-gray-500'} tagClassName={'bg-black bg-opacity-10'}/>
    <ButtonBar className={'opacity-99 flex mt-4 -ml-2 -mb-2'}/>
  </div>
}

function MobileProjectCardContent() {
  return <div className={'flex flex-col items-center'}>
    <Title className={'mt-6'}/>
    <CardCarousel small className={'transform h-[32rem] flex items-center mt-6'}/>
    <Introduction className={'max-w-[36rem] mt-6 px-6'}/>
    <FeaturesGrid className={'mt-6'}/>
    <FeaturedNumbers data={featuredNumbers} className={'mt-6'}/>
    <TechnologyCards data={technologyCardsData} className={'mt-6'} cardClassName={'border-gray-350 mx-3'} tagClassName={'bg-black bg-opacity-10'}/>
    <ButtonBar className={'flex flex-wrap my-4 justify-center'}/>
  </div>
}

const taProjectCardRefState = atom<RefObject<HTMLDivElement>>({current: null})

export {taProjectCardRefState}

export default function TeachAssistProjectCard() {
  const ref = useRef<HTMLDivElement>(null)
  const [, setTaProjectCardRef] = useAtom(taProjectCardRefState)
  useEffect(() => {
    setTaProjectCardRef(ref)
  }, [ref, setTaProjectCardRef])

  return <ProjectCard ref={ref}>
    <Media greaterThanOrEqual={'xl'}>
      <DesktopProjectCardContent/>
    </Media>
    <Media lessThan={'xl'}>
      <MobileProjectCardContent/>
    </Media>
  </ProjectCard>
}