import ProjectCard from '../ProjectCard'
import Image from '../../../../components/Image'
import pegaDriveLogo from './pegaDriveLogo.svg'
import {PropsWithChildren, PropsWithClassName} from '../../../../utils'
import {mdiCogs, mdiDatabaseOutline, mdiHarddisk, mdiWeb} from '@mdi/js'
import Icon from '@mdi/react'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import driveScreenshot from './driveScreenshot.jpg'
import driveScreenshotAvif from './driveScreenshot.avif'
import {InDevelopmentBanner} from '../InDevelopmentBanner'
import {h} from 'preact'

function Title() {
  return <div className={'flex items-center'}>
    <p className={'w-14 inline-block flex-none'}>
      <Image src={pegaDriveLogo}/>
    </p>
    <div className={'ml-2'}>
      <p className={'text-2xl font-bold'}>Pega Drive</p>
      <p className={'text-gray-600'}>Serverless cloud drive without limits.</p>
    </div>
  </div>
}

function LayerBox(props: PropsWithChildren<PropsWithClassName<{ title: string, icon: string }>>) {
  return <div className={`bg-gray-200 rounded flex flex-col items-center p-2 ${props.className ?? ''}`}>
    <div className={'flex justify-center items-center'}>
      <Icon path={props.icon} size={'2rem'} className={'mr-1'}/>
      <span className={'text-xl'}>{props.title}</span>
    </div>
    {props.children}
  </div>
}

export default function PegaDriveProjectCard() {
  return <ProjectCard className={'p-6'}>
    <InDevelopmentBanner/>
    <Title/>
    <div className={'grid justify-items-stretch grid-cols-4 gap-4 mt-4'}>
      <LayerBox title={'Data Layer'} icon={mdiDatabaseOutline} className={'col-span-2'}>
        <TechnologyTag data={TechnologyTags.mongoDBAtlas} className={'border-2 border-gray-400'} spacing={'pl-2 pr-3 py-1 mt-2'}/>
        <p className={'mt-2'}>
          Mongodb Atlas&apos; serverless database scale as I need it plus the benefits of a schemaless database.
        </p>
      </LayerBox>

      <LayerBox title={'Storage Layer'} icon={mdiHarddisk} className={'col-span-2'}>
        <TechnologyTag data={TechnologyTags.r2} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mt-2'}/>
        <p className={'mt-2'}>
          R2 is an object storage with no egress fees, making it suitable for a cloud drive.
        </p>
      </LayerBox>

      <LayerBox title={'Compute Layer'} icon={mdiCogs} className={'col-span-3'}>
        <div className={'flex flex-wrap justify-center items-center'}>
          <TechnologyTag data={TechnologyTags.nextjsAPI} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mt-2'}/>
          <TechnologyTag data={TechnologyTags.ts} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mx-2 mt-2'}/>
          <TechnologyTag data={TechnologyTags.vercel} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mt-2'}/>
        </div>
        <p className={'mt-2'}>
          Nextjs API allows me to reuse code between server and client. Vercel provides serverless hosting for the code.
        </p>
        <p>
          By using some smart math, I&apos;m able to allow users to securely & directly download files from R2 which does not support authentication.
        </p>
      </LayerBox>

      <div className={'col-start-4 col-span-1 relative mr-4 -my-2'}>
        <svg width="50px" className={'absolute h-full horizontal-absolute-center'}>
          <defs>
            <polyline id="arrow-head"
                      points="10,-15, 25,-2.5 40,-15"
                      fill="none" stroke="black" strokeWidth="4"/>
          </defs>
          <line x1="50%" y1="0" x2="50%" y2="100%" stroke="black" strokeWidth="4" transform="translate(0,-3)"/>
          <use y="100%" xlinkHref="#arrow-head"/>
        </svg>
        <p className={'absolute horizontal-absolute-center vertical-absolute-center bg-white z-10 text-center'}>
          Direct Download
        </p>
      </div>
      <LayerBox title={'Frontend'} icon={mdiWeb} className={'col-span-4'}>
        <div className={'flex flex-wrap justify-center mb-1 text-center'}>
          <TechnologyTag data={TechnologyTags.nextjs} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mt-2'}/>
          <TechnologyTag data={TechnologyTags.ts} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mx-2 mt-2'}/>
          <TechnologyTag data={TechnologyTags.tailwindCSS} className={'border-2 border-gray-400'} spacing={'px-2 py-1 mt-2'}/>
        </div>
        <div className={'w-full mt-2'}>
          <Image src={[driveScreenshotAvif, driveScreenshot]} className={'rounded'}/>
        </div>
      </LayerBox>
    </div>
  </ProjectCard>
}