import {m, useTransform, useViewportScroll} from 'framer-motion'
import {finishColors, PropsWithChildren, startColors, useRefScrollProgress} from '../../../utils'
import {taProjectCardRefState} from '../projects/ta/TeachAssistProjectCard'
import {navBarSelectState, NavTitles} from './NavBar'
import {useAtomValue} from 'jotai/utils'
import {h} from 'preact'

function NavLink(props: PropsWithChildren<{
  href: string,
  selected?: boolean,
  onClick?: (event: { preventDefault: () => void }) => void,
  startColor: string,
  finishColor: string,
}>) {
  const animateSelected = {
    '--start': props.startColor,
    '--finish': props.finishColor,
    fontSize: '1.875rem',
    lineHeight: '2.25rem',
    fontWeight: 700,
    '--skew': '-12deg',
  }
  const animateNotSelected = {
    '--start': '#000000',
    '--finish': '#000000',
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
    fontWeight: 400,
    '--skew': '0deg',
  }
  const animate = props.selected ? animateSelected : animateNotSelected

  return <m.a
    href={props.href}
    className={'block gradient-text'}
    onClick={props.onClick}
    style={{
      backgroundImage: `linear-gradient(to right, var(--start) 0%, var(--finish) 100%)`,
      transform: 'skew(var(--skew))',
    }}
    initial={animateNotSelected as any}
    animate={animate as any}
    transition={{type: 'spring', bounce: 0, duration: 0.2}}>
    {props.children}
  </m.a>
}

function SideBarInner(props: { titles: NavTitles }) {
  const selectedI = useAtomValue(navBarSelectState)

  return <div className={'flex flex-col'}>
    {props.titles.map(({text, href, ref}, i) => <NavLink
      key={i}
      href={href}
      selected={selectedI === i}
      onClick={e => {
        e.preventDefault()
        window.scrollBy({
          top: ref!.current!.getBoundingClientRect().top - 32,
          behavior: 'smooth',
        })
      }}
      startColor={startColors[i]}
      finishColor={finishColors[i]}>
      {text}
    </NavLink>)}
  </div>
}

export default function WideSideBar(props: { titles: NavTitles }) {
  const taProjectCardRef = useAtomValue(taProjectCardRefState)
  const {start, end} = useRefScrollProgress(taProjectCardRef, 0.25)
  const {scrollYProgress} = useViewportScroll()

  const transform = useTransform(useTransform(scrollYProgress, [start, end], [-2, 0]), percent => `translateX(${percent * 100}%) translateY(-50%)`)

  return <m.div
    className={'fixed left-4 top-1/2'}
    style={{transform}}>
    <SideBarInner titles={props.titles}/>
  </m.div>
}