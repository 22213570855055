import ProjectCard from '../ProjectCard'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import Button from '../../../../components/Button'
import {h} from 'preact'

export function KeyboardAwareDialogCard() {
  return <ProjectCard className={'p-6 flex flex-col items-start'}>
    <p className={'text-3xl'}>Keyboard Aware Dialog</p>
    <p className={'text-gray-600'}>
      Fixes another annoying Flutter bug where the dialog won&apos;t move up when the keyboard is open.
    </p>
    <div className={'flex'}>
      <TechnologyTag data={TechnologyTags.dart} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2 mr-2'}/>
      <TechnologyTag data={TechnologyTags.flutter} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2'}/>
    </div>
    <div className={'flex mt-4 -ml-2 -mb-2'}>
      <Button href={'https://github.com/PegasisForever/flutter_keyboard_aware_dialog'} className={'bg-[#2b66b8] text-white'}>
        Github
      </Button>
      <Button href={'https://pub.dev/packages/flutter_keyboard_aware_dialog'} className={'bg-[#2b66b8] text-white'}>
        pub.dev
      </Button>
    </div>
  </ProjectCard>
}