import taScreenShot1 from './screenshots/1.png'
import taScreenShot2 from './screenshots/2.png'
import taScreenShot3 from './screenshots/3.png'
import taScreenShot4 from './screenshots/4.png'
import taScreenShot5 from './screenshots/5.png'
import taScreenShot6 from './screenshots/6.png'
import taScreenShot7 from './screenshots/7.png'
import taScreenShot8 from './screenshots/8.png'
import taScreenShot9 from './screenshots/9.png'
import taScreenShot1Avif from './screenshots/1.avif'
import taScreenShot2Avif from './screenshots/2.avif'
import taScreenShot3Avif from './screenshots/3.avif'
import taScreenShot4Avif from './screenshots/4.avif'
import taScreenShot5Avif from './screenshots/5.avif'
import taScreenShot6Avif from './screenshots/6.avif'
import taScreenShot7Avif from './screenshots/7.avif'
import taScreenShot8Avif from './screenshots/8.avif'
import taScreenShot9Avif from './screenshots/9.avif'
import {m} from 'framer-motion'
import {PropsWithClassName, useIntervalWhenInView} from '../../../../utils'
import {h} from 'preact'
import Image from '../../../../components/Image'
import {useCallback, useState} from 'preact/compat'

const screenShots = [
  [taScreenShot1Avif, taScreenShot1],
  [taScreenShot2Avif, taScreenShot2],
  [taScreenShot3Avif, taScreenShot3],
  [taScreenShot4Avif, taScreenShot4],
  [taScreenShot5Avif, taScreenShot5],
  [taScreenShot6Avif, taScreenShot6],
  [taScreenShot7Avif, taScreenShot7],
  [taScreenShot8Avif, taScreenShot8],
  [taScreenShot9Avif, taScreenShot9],
]

function ImageCard(props: { level: 1 | 2 | 3 | 4, src: string[], small?: boolean }) {
  const width = (props.small ? ['16rem', '13rem', '10rem', '7rem'] : ['20rem', '16rem', '12rem', '8rem'])[props.level - 1]
  const overlayOpacity = [0, 0.33, 0.66, 1][props.level - 1]
  const shadowOpacity = [1, 0.75, 0.5, 0][props.level - 1]
  const margin = '-3rem'
  const zIndex = [0, -10, -20, -30][props.level - 1]

  return <m.div
    initial={false}
    layout
    layoutId={props.src[0]}
    className={'h-auto rounded overflow-hidden relative'}
    style={{
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, calc(0.1 * var(--shadow-opacity))), 0 2px 4px -1px rgba(0, 0, 0, calc(0.06 * var(--shadow-opacity)))',
    }}
    animate={{
      '--shadow-opacity': shadowOpacity,
      width,
      marginLeft: margin,
      marginRight: margin,
      zIndex,
    } as any}
    transition={{type: 'spring', bounce: 0, duration: 0.5}}>
    <Image priority src={props.src}/>
    <m.div
      layoutId={`${props.src}-overlay`}
      className={'absolute w-full h-full left-0 top-0 bg-white'}
      initial={false}
      animate={{opacity: overlayOpacity}}
      transition={{type: 'spring', bounce: 0, duration: 0.5}}/>
  </m.div>
}

export default function CardCarousel(props: PropsWithClassName<{ small?: boolean }>) {
  const [screenShotI, setScreenShotI] = useState(6)
  const ref = useIntervalWhenInView(
    useCallback(() => setScreenShotI(old => old + 1), []),
    4000,
  )

  // absolute right-[calc(40%-38rem)] vertical-absolute-center opacity-99
  return <div ref={ref} className={`anchor-none ${props.className ?? ''}`}>
    <m.div layout className={'flex items-center'}>
      <ImageCard small={props.small} key={screenShotI % screenShots.length} src={screenShots[screenShotI % screenShots.length]} level={4}/>
      <ImageCard small={props.small} key={(screenShotI + 1) % screenShots.length} src={screenShots[(screenShotI + 1) % screenShots.length]} level={3}/>
      <ImageCard small={props.small} key={(screenShotI + 2) % screenShots.length} src={screenShots[(screenShotI + 2) % screenShots.length]} level={2}/>
      <ImageCard small={props.small} key={(screenShotI + 3) % screenShots.length} src={screenShots[(screenShotI + 3) % screenShots.length]} level={1}/>
      <ImageCard small={props.small} key={(screenShotI + 4) % screenShots.length} src={screenShots[(screenShotI + 4) % screenShots.length]} level={2}/>
      <ImageCard small={props.small} key={(screenShotI + 5) % screenShots.length} src={screenShots[(screenShotI + 5) % screenShots.length]} level={3}/>
      <ImageCard small={props.small} key={(screenShotI + 6) % screenShots.length} src={screenShots[(screenShotI + 6) % screenShots.length]} level={4}/>
    </m.div>
  </div>
}
