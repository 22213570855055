import Icon from '@mdi/react'
import {mdiBellOutline, mdiLightningBoltOutline, mdiTransition, mdiViewQuiltOutline} from '@mdi/js'
import {PropsWithClassName} from '../../../../utils'
import {h} from 'preact'

function GridItem(props: { icon: string, text: string }) {
  return <p className={'text-lg'}>
    <Icon path={props.icon}
          size={'2rem'}
          className={'inline mr-2 xs:mr-1'}/>
    {props.text}
  </p>
}

export default function FeaturesGrid(props: PropsWithClassName<{}>) {
  return <div className={`max-w-[530px] grid grid-cols-1 xs:grid-cols-2 gap-2 ${props.className ?? ''}`}>
    <GridItem icon={mdiLightningBoltOutline} text={'Blazing fast speed'}/>
    <GridItem icon={mdiViewQuiltOutline} text={'Carefully designed UI'}/>
    <GridItem icon={mdiTransition} text={'Smooth animations'}/>
    <GridItem icon={mdiBellOutline} text={'Push notifications'}/>
  </div>
}
