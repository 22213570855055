import {useInView} from 'react-hook-inview'
import useWindowFocus from 'use-window-focus'
import {atom} from 'jotai'
import {useAtomValue} from 'jotai/utils'
import {ComponentChildren} from 'preact'
import {Ref, useEffect, useState} from 'preact/compat'
import {useWindowSize} from 'react-use'

export interface Size {
  width: number | undefined,
  height: number | undefined,
}

export const bodyHeightState = atom<number | undefined>(undefined)

export function useRefScrollProgress(ref: Ref<any> | null, finishPercent = 0.5) {
  const [start, setStart] = useState<number>(0)
  const [end, setEnd] = useState<number>(1)
  const windowSize = useWindowSize()
  const bodyHeight = useAtomValue(bodyHeightState)
  useEffect(() => {
    if (!ref || !ref.current || !windowSize.height || !bodyHeight) {
      return
    }
    if (windowSize.height > document.body.clientHeight) {
      setStart(0)
      setEnd(1)
      return
    }
    const rect = ref.current.getBoundingClientRect()
    const offsetTop = rect.top + window.scrollY
    setStart((offsetTop - windowSize.height) / (document.body.clientHeight - windowSize.height))
    setEnd((offsetTop - windowSize.height * finishPercent) / (document.body.clientHeight - windowSize.height))
  }, [finishPercent, ref, windowSize, bodyHeight])
  return {start, end}
}

export const startColors = [
  '#007cf0',
  '#7928ca',
  '#e84848',
  '#48b015',
]

export const finishColors = [
  '#00dfd8',
  '#ff0080',
  '#f8a726',
  '#a9e300',
]

export type PropsWithClassName<T> = T & { className?: string }
export type PropsWithChildren<T> = T & { children?: ComponentChildren }

export function range(n: number): number[] {
  return [...Array(n).keys()]
}

export function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max)
}

export async function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function useIntervalWhenInView<T>(callback: () => void, intervalMs: number): (node: T | null) => void {
  const [ref, isVisible] = useInView({threshold: 0})
  const windowFocused = useWindowFocus()

  useEffect(() => {
    if (isVisible && windowFocused) {
      const intervalID = setInterval(callback, intervalMs)
      return () => clearInterval(intervalID)
    }
  }, [isVisible, windowFocused, intervalMs, callback])

  return ref as (node: T | null) => void
}

export function constrainMagnitude(a: number, max: number): number {
  if (a < 0) {
    if (-a > max) {
      return -max
    } else {
      return a
    }
  } else {
    if (a > max) {
      return max
    } else {
      return a
    }
  }
}

export function constrain(a: number, min: number, max: number): number {
  if (a < min) {
    return min
  } else if (a > max) {
    return max
  } else {
    return a
  }
}