import ProjectCard from '../ProjectCard'
import grokLogo from './grok_logo.svg'
import LazyVideo from '../../../../components/LazyVideo'
import firstFrame from './videoFirstFrame.jpg'
import {mdiAbTesting, mdiLightningBoltOutline, mdiMaterialDesign, mdiWindowRestore} from '@mdi/js'
import Icon from '@mdi/react'
import {TechnologyCards, TechnologyTags} from '../../../../components/TechnologyCard'
import {Media} from '../../../../media'
import Title from '../../../../components/Title'
import { h } from 'preact'
import {PropsWithChildren} from '../../../../utils'

const technologyCardsData = [
  {title: 'Serverless', tags: [TechnologyTags.leanCloud]},
  {title: 'Native Android App', tags: [TechnologyTags.kotlin]},
]

function FeatureCard(props: PropsWithChildren<{ title: string, description: string, icon: string }>) {
  return <div className={'bg-gray-200 rounded p-4'}>
    <p className={'text-lg font-bold mb-2'}>
      <Icon path={props.icon}
            size={'2rem'}
            className={'inline mr-2'}/>
      {props.title}
    </p>
    {props.children ? props.children : <p className={'mt-2'}>{props.description}</p>}
  </div>
}

function DesktopProjectCardContent() {
  return <div className={'flex flex-col items-start p-6'}>
    <div className={'w-[40%] absolute right-0 vertical-absolute-center flex items-center justify-center'}>
      <div className={'rounded-lg overflow-hidden shadow-xl w-[min(80%,320px)]'}>
        <LazyVideo w={608} h={1080} src={'/videos/grok.mp4'} loadingImage={firstFrame}/>
      </div>
    </div>
    <Title imageClassName={'w-20'} logo={grokLogo} title={'GROK'} subTitle={'Channelled messaging app, before* Discord.'}/>
    <p className={'mt-4'}>
      (I Made this in grade 10, it&apos;s pretty bad in today&apos;s standard.)
    </p>
    <div className={'grid grid-cols-2 gap-4 w-[60%] mt-6'}>
      <FeatureCard icon={mdiAbTesting} title={'Chat in Different Channels'} description={'I promise you I got this idea before learning discord is a thing :)'}/>
      <FeatureCard icon={mdiMaterialDesign} title={'Follows Material Design'} description={'Because who doesn\'t love the ripple effect and smooth animations?'}/>
      <FeatureCard icon={mdiLightningBoltOutline} title={'Blazing Fast'} description={''}>
        <ul className={'list-disc pl-6'}>
          <li>Using native components</li>
          <li>Utilizing multiple cores</li>
          <li>Lazy loading</li>
        </ul>
      </FeatureCard>
      <FeatureCard icon={mdiWindowRestore} title={'Mouse, Keyboard and Multi-Window Support'} description={'Just because I can.'}/>
    </div>
    <TechnologyCards data={technologyCardsData} className={'mt-6 -ml-6'} cardClassName={'border-gray-400 mx-3'} tagClassName={'bg-gray-200'}/>
    <p className={'text-gray-500 mt-4'}>* By before I mean before I heard of Discord.</p>
    <p className={'text-gray-500'}>
      Currently closed due to LeanCloud (a Chinese company)&apos;s real name verification requirements.
    </p>
  </div>
}

function MobileProjectCardContent() {
  return <div className={'flex flex-col items-center'}>
    <Title className={'mt-6'} imageClassName={'w-20'} logo={grokLogo} title={'GROK'} subTitle={'Channelled messaging app, before* Discord.'}/>
    <p className={'mt-6 text-center'}>
      (I Made this in grade 10, it&apos;s pretty bad in today&apos;s standard.)
    </p>
    <div className={'rounded-lg overflow-hidden shadow-xl mt-6 w-[calc(100%-3rem)] max-w-xs'}>
      <LazyVideo w={608} h={1080} src={'/videos/grok.mp4'} loadingImage={firstFrame}/>
    </div>

    <div className={'grid grid-cols-1 xs:grid-cols-2 gap-4 mx-6 mt-8'}>
      <FeatureCard icon={mdiAbTesting} title={'Chat in Different Channels'} description={'I promise you I got this idea before learning discord is a thing :)'}/>
      <FeatureCard icon={mdiMaterialDesign} title={'Follows Material Design'} description={'Because who doesn\'t love the ripple effect and smooth animations?'}/>
      <FeatureCard icon={mdiLightningBoltOutline} title={'Blazing Fast'} description={''}>
        <ul className={'list-disc pl-6'}>
          <li>Using native components</li>
          <li>Utilizing multiple cores</li>
          <li>Lazy loading</li>
        </ul>
      </FeatureCard>
      <FeatureCard icon={mdiWindowRestore} title={'Mouse, Keyboard and Multi-Window Support'} description={'Just because I can.'}/>
    </div>
    <TechnologyCards data={technologyCardsData} className={'mt-6'} cardClassName={'border-gray-400 mx-3'} tagClassName={'bg-gray-200'}/>
    <p className={'text-gray-500 mt-6 px-6 w-full'}>* By before I mean before I heard of Discord.</p>
    <p className={'text-gray-500 mt-2 px-6 pb-6 w-full'}>
      Currently closed due to LeanCloud (a Chinese company)&apos;s real name verification requirements.
    </p>
  </div>
}

export default function GrokProjectCard() {
  return <ProjectCard>
    <Media greaterThanOrEqual={'xl'}>
      <DesktopProjectCardContent/>
    </Media>
    <Media lessThan={'xl'}>
      <MobileProjectCardContent/>
    </Media>
  </ProjectCard>
}