import {PropsWithClassName} from '../utils'
import { h } from 'preact'
import Image from './Image'

export default function Title(props: PropsWithClassName<{ logo: string, title: string, subTitle: string, imageClassName: string }>) {
  return <div className={`flex flex-col xs:flex-row items-center ${props.className ?? ''}`}>
    <p className={`inline-block ${props.imageClassName ?? ''}`}>
      <Image priority src={props.logo}/>
    </p>
    <div className={'mt-2 xs:mt-0 xs:ml-2 text-center xs:text-left'}>
      <p className={'text-4xl'}>{props.title}</p>
      <p className={'text-gray-600'}>{props.subTitle}</p>
    </div>
  </div>
}