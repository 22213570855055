import ProjectCard from '../ProjectCard'
import decide4meLogo from './decide4meLogo.svg'
import Image from '../../../../components/Image'
import {mdiMedal} from '@mdi/js'
import Icon from '@mdi/react'
import {useCallback, useState} from 'preact/compat'
import {AnimatePresence, m} from 'framer-motion'
import {ImagePollCard, TextPollCard} from './PollCard'
import {useIntervalWhenInView} from '../../../../utils'
import {TechnologyCards, TechnologyTags} from '../../../../components/TechnologyCard'
import Button from '../../../../components/Button'
import { h } from 'preact'

const technologyCardsData = [
  {title: 'Serverless', tags: [TechnologyTags.cloudRun, TechnologyTags.python, TechnologyTags.firebase, TechnologyTags.netlify]},
  {title: 'Website', tags: [TechnologyTags.ts, TechnologyTags.react, TechnologyTags.mui, TechnologyTags.framerMotion]},
]

function Title() {
  return <div className={'flex items-center'}>
    <p className={'w-14 inline-block flex-none'}>
      <Image src={decide4meLogo}/>
    </p>
    <div className={'ml-2'}>
      <div className={'flex items-center justify-end flex-row-reverse flex-wrap'}>
        <span className={'font-normal text-lg bg-gray-300 rounded-full pl-2 pr-2.5 py-0.5'}>
          <Icon path={mdiMedal} size={'1.5rem'} className={'inline -mt-0.5 text-[#ff6b00]'}/>
          Best Use of Cloud Computing
        </span>
        <span className={'text-2xl font-bold mr-4'}>
          Decide 4 Me
        </span>
      </div>
      <p className={'text-gray-600'}>Let the internet decide for you.</p>
    </div>
  </div>
}

function CreatePollText(props: { isImage: boolean }) {
  const animateProps = {
    initial: {
      opacity: 0,
      translateY: '-50%',
    },
    animate: {
      opacity: 1,
      translateY: '0%',
    },
    exit: {
      opacity: 0,
      translateY: '50%',
    },
    transition: {
      type: 'tween',
      ease: 'easeInOut',
    },
    className: 'absolute left-0 top-0',
  }

  return <div className={'mt-4 text-xl flex justify-center'}>
    <span>Create poll on&nbsp;</span>
    <div className={'relative font-bold'}>
      <span className={'invisible'}>Images</span>
      <AnimatePresence>
        {props.isImage ?
          <m.span key={'image'} {...animateProps}>Images</m.span> :
          <m.span key={'text'} {...animateProps}>Texts</m.span>}
      </AnimatePresence>
    </div>
  </div>
}

export default function Decide4MeProjectCard() {
  const [round, setRound] = useState(1)
  const ref = useIntervalWhenInView(
    useCallback(() => setRound(old => old + 1), []),
    5000,
  )

  return <ProjectCard ref={ref} className={'p-6'}>
    <Title/>
    <p className={'mt-4'}>
      Made with{' '}
      <a href={'https://alex-xu.site/'} className={'underline text-blue-700'} target={'_blank'} rel={'noreferrer'}>@Alex_Xu</a>{' '}
      in 24 hours as a submission for{' '}
      <a href={'https://yrhacks.ca/'} className={'underline text-blue-700'} target={'_blank'} rel={'noreferrer'}>YRHacks</a>.
    </p>
    <CreatePollText isImage={round % 2 === 0}/>
    <div className={'mt-4 h-96 relative'}>
      <ImagePollCard key={`image-${Math.floor(round / 2)}`} show={round % 2 === 0}/>
      <TextPollCard key={`text-${Math.floor((round + 1) / 2)}`} show={round % 2 === 1}/>
    </div>
    <div className={'flex justify-center'}>
      <TechnologyCards data={technologyCardsData} cardClassName={'border-gray-400'} tagClassName={'bg-gray-200'}/>
    </div>
    <div className={'flex flex-wrap justify-center mt-4 pl-4'}>
      <Button href={'https://decide4me.pegas.is/'} className={'bg-[#4350af] text-white mt-2'}>
        Website
      </Button>
      <Button href={'https://github.com/PegasisForever/decide4me_frontend'} className={'bg-[#4350af] text-white mt-2'}>
        Github (Website)
      </Button>
      <Button href={'https://github.com/AlexZihaoXu/decide4me_backend'} className={'bg-[#4350af] text-white mt-2'}>
        Github (Server)
      </Button>
    </div>
  </ProjectCard>
}