import ProjectCard from '../ProjectCard'
import Image from '../../../../components/Image'
import backgroundImage from './bg.jpg'
import punoIcon from './punoIcon.svg'
import {mdiFormatQuoteClose} from '@mdi/js'
import Icon from '@mdi/react'
import Button from '../../../../components/Button'
import firstFrame from './videoFirstFrame.jpg'
import LazyVideo from '../../../../components/LazyVideo'
import {TechnologyCards, TechnologyTags} from '../../../../components/TechnologyCard'
import {FeaturesGrid} from './FeaturesGrid'
import {Media} from '../../../../media'
import {PropsWithClassName} from '../../../../utils'
import {Fragment, h} from 'preact'

const technologyCardsData = [
  {title: 'Serverless', tags: [TechnologyTags.firebaseAuth, TechnologyTags.firebase, TechnologyTags.netlify]},
  {title: 'Website', tags: [TechnologyTags.ts, TechnologyTags.react, TechnologyTags.framerMotion]},
]

function Title(props: PropsWithClassName<{}>) {
  return <Fragment>
    <div className={`w-40 ${props.className ?? ''}`}>
      <Image src={punoIcon}/>
    </div>
    <p className={'mt-2 opacity-99'}>
      The best{' '}
      <a className={'underline'} href={'https://en.wikipedia.org/wiki/Uno_(card_game)'} target={'_blank'}
         rel={'noreferrer'}>uno game</a>
      {' '}on the internet.
    </p>
  </Fragment>
}

function Video(props: PropsWithClassName<{ boxShadow: string }>) {
  return <div
    className={props.className}
    style={{boxShadow: props.boxShadow}}>
    <LazyVideo w={624} h={653} src={'/videos/puno.m4v'} loadingImage={firstFrame}/>
  </div>
}

function QuoteItem(props: { text: string }) {
  return <div className={'relative flex flex-row items-center'}>
    <Icon path={mdiFormatQuoteClose}
          size={'6rem'}
          className={'inline opacity-30 -m-4 -mr-16'}/>
    <span className={'text-xl pb-4 xs:pb-2'}>
         {props.text}
        </span>
    <span className={'text-sm absolute right-0 bottom-1 xs:static xs:pt-4 xs:pl-6'}>
          — Reddit user
        </span>
  </div>
}

function Quotes(props: PropsWithClassName<{}>) {
  return <div className={`opacity-99 ${props.className ?? ''}`}>
    <QuoteItem text={'The animations are insane.'}/>
    <QuoteItem text={'OMG thank you so much!'}/>
  </div>
}

function ButtonBar(props: PropsWithClassName<{}>) {
  return <div className={props.className}>
    <Button href={'https://puno.pegas.is/'} className={'bg-[#931dc4] text-white'}>
      Play It Online
    </Button>
    <Button
      href={'https://www.reddit.com/r/unocardgame/comments/o8fk8z/i_made_an_online_uno_that_runs_in_your_browser/'}
      className={'bg-[#6c2c86] text-white'}>
      Reddit Post
    </Button>
  </div>
}

const backgroundStyle = {backgroundImage: `url(${backgroundImage})`, backgroundAttachment:'local', backgroundSize: '100% 100%'}

function DesktopProjectCardContent() {
  return <div className={'flex flex-col items-start p-6'} style={backgroundStyle}>
    <Video className={'w-[min(40%,520px)] absolute right-[max(calc(35%-20rem),1rem)] vertical-absolute-center rounded-lg overflow-hidden'}
           boxShadow={'0px 0px 32px 0px rgba(255, 255, 255, 0.7)'}/>
    <div>
      <Title/>
    </div>
    <FeaturesGrid className={'w-[60%] max-w-[530px] mt-6'}/>
    <Quotes className={'mt-4'}/>
    <TechnologyCards data={technologyCardsData} className={'mt-6 -ml-6 opacity-99'} cardClassName={'border-gray-400'} tagClassName={'bg-white bg-opacity-20'}/>
    <ButtonBar className={'opacity-99 flex mt-4 -ml-2 -mb-2'}/>
  </div>
}

function MobileProjectCardContent() {
  return <div className={'flex flex-col items-center'} style={backgroundStyle}>
    <Title className={'mt-6'}/>
    <Video className={'w-full max-w-md rounded-lg overflow-hidden opacity-99 mt-6'}
           boxShadow={'0px 0px 16px 0px rgba(255, 255, 255, 0.6)'}/>
    <FeaturesGrid className={'mt-8'}/>
    <Quotes className={'mt-4'}/>
    <TechnologyCards data={technologyCardsData} className={'mt-6 opacity-99'} cardClassName={'border-gray-400 mx-3'} tagClassName={'bg-white bg-opacity-20'}/>
    <ButtonBar className={'opacity-99 flex flex-wrap my-4 justify-center'}/>
  </div>
}

export default function PunoProjectCard() {
  return <ProjectCard className={'text-white'}>
    <Media greaterThanOrEqual={'xl'}>
      <DesktopProjectCardContent/>
    </Media>
    <Media lessThan={'xl'}>
      <MobileProjectCardContent/>
    </Media>
  </ProjectCard>
}
