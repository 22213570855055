import produce from 'immer'

export type TagPos = {
  centerX: number,
  centerY: number,
  w: number,
  h: number,
  mass: number,
}

export type TagPosCacheItem = {
  containerW: number,
  containerH: number,
  data: TagPos[],
}

const tagPos: TagPosCacheItem[] = [{
  containerW: 1500,
  containerH: 1710,
  data: [{centerX: 885, centerY: 1604, w: 536, h: 50, mass: 26800}, {centerX: 849, centerY: 1398, w: 403, h: 50, mass: 20150}, {
    centerX: 1201,
    centerY: 585,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 347, centerY: 767, w: 525, h: 50, mass: 26250}, {centerX: 326, centerY: 1435, w: 470, h: 50, mass: 23500}, {
    centerX: 1182,
    centerY: 1206,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 614, centerY: 550, w: 615, h: 50, mass: 30750}, {centerX: 1232, centerY: 1507, w: 373, h: 50, mass: 18650}, {
    centerX: 706,
    centerY: 1028,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 389, centerY: 85, w: 302, h: 50, mass: 15100}, {centerX: 336, centerY: 1600, w: 466, h: 50, mass: 23300}, {
    centerX: 383,
    centerY: 1225,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 1134, centerY: 946, w: 298, h: 50, mass: 14900}, {centerX: 1119, centerY: 328, w: 432, h: 50, mass: 21600}, {
    centerX: 949,
    centerY: 764,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 392, centerY: 357, w: 563, h: 50, mass: 28150}, {centerX: 364, centerY: 190, w: 439, h: 50, mass: 21950}, {
    centerX: 1064,
    centerY: 128,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 293, centerY: 996, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 1400,
  containerH: 1832,
  data: [{centerX: 1095, centerY: 213, w: 536, h: 50, mass: 26800}, {centerX: 571, centerY: 603, w: 403, h: 50, mass: 20150}, {
    centerX: 1077,
    centerY: 1215,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 507, centerY: 301, w: 525, h: 50, mass: 26250}, {centerX: 791, centerY: 100, w: 470, h: 50, mass: 23500}, {
    centerX: 910,
    centerY: 1607,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 1048, centerY: 730, w: 615, h: 50, mass: 30750}, {centerX: 1059, centerY: 446, w: 373, h: 50, mass: 18650}, {
    centerX: 1078,
    centerY: 1733,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 313, centerY: 787, w: 302, h: 50, mass: 15100}, {centerX: 296, centerY: 127, w: 466, h: 50, mass: 23300}, {
    centerX: 1031,
    centerY: 992,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 275, centerY: 475, w: 298, h: 50, mass: 14900}, {centerX: 1064, centerY: 1423, w: 432, h: 50, mass: 21600}, {
    centerX: 274,
    centerY: 1707,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 321, centerY: 1515, w: 563, h: 50, mass: 28150}, {centerX: 536, centerY: 1289, w: 439, h: 50, mass: 21950}, {
    centerX: 356,
    centerY: 1042,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 573, centerY: 1728, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 1300,
  containerH: 1973,
  data: [{centerX: 968, centerY: 263, w: 536, h: 50, mass: 26800}, {centerX: 392, centerY: 851, w: 403, h: 50, mass: 20150}, {
    centerX: 897,
    centerY: 974,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 328, centerY: 1106, w: 525, h: 50, mass: 26250}, {centerX: 851, centerY: 105, w: 470, h: 50, mass: 23500}, {
    centerX: 322,
    centerY: 131,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 960, centerY: 716, w: 615, h: 50, mass: 30750}, {centerX: 926, centerY: 1693, w: 373, h: 50, mass: 18650}, {
    centerX: 606,
    centerY: 1434,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 391, centerY: 1583, w: 302, h: 50, mass: 15100}, {centerX: 370, centerY: 336, w: 466, h: 50, mass: 23300}, {
    centerX: 951,
    centerY: 1858,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 999, centerY: 1494, w: 298, h: 50, mass: 14900}, {centerX: 304, centerY: 1745, w: 432, h: 50, mass: 21600}, {
    centerX: 269,
    centerY: 1358,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 348, centerY: 580, w: 563, h: 50, mass: 28150}, {centerX: 453, centerY: 1872, w: 439, h: 50, mass: 21950}, {
    centerX: 957,
    centerY: 1225,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 953, centerY: 475, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 1200,
  containerH: 2138,
  data: [{centerX: 846, centerY: 2036, w: 536, h: 50, mass: 26800}, {centerX: 881, centerY: 957, w: 403, h: 50, mass: 20150}, {
    centerX: 859,
    centerY: 676,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 585, centerY: 1925, w: 525, h: 50, mass: 26250}, {centerX: 893, centerY: 1295, w: 470, h: 50, mass: 23500}, {
    centerX: 854,
    centerY: 242,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 353, centerY: 746, w: 615, h: 50, mass: 30750}, {centerX: 866, centerY: 1539, w: 373, h: 50, mass: 18650}, {
    centerX: 878,
    centerY: 457,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 340, centerY: 276, w: 302, h: 50, mass: 15100}, {centerX: 347, centerY: 479, w: 466, h: 50, mass: 23300}, {
    centerX: 349,
    centerY: 1493,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 385, centerY: 979, w: 298, h: 50, mass: 14900}, {centerX: 903, centerY: 1763, w: 432, h: 50, mass: 21600}, {
    centerX: 272,
    centerY: 2026,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 318, centerY: 1750, w: 563, h: 50, mass: 28150}, {centerX: 356, centerY: 113, w: 439, h: 50, mass: 21950}, {
    centerX: 351,
    centerY: 1201,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 831, centerY: 99, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 1100,
  containerH: 2332,
  data: [{centerX: 815, centerY: 2089, w: 536, h: 50, mass: 26800}, {centerX: 692, centerY: 108, w: 403, h: 50, mass: 20150}, {
    centerX: 296,
    centerY: 153,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 816, centerY: 1698, w: 525, h: 50, mass: 26250}, {centerX: 802, centerY: 1301, w: 470, h: 50, mass: 23500}, {
    centerX: 307,
    centerY: 2000,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 451, centerY: 860, w: 615, h: 50, mass: 30750}, {centerX: 755, centerY: 519, w: 373, h: 50, mass: 18650}, {
    centerX: 307,
    centerY: 616,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 378, centerY: 385, w: 302, h: 50, mass: 15100}, {centerX: 298, centerY: 1647, w: 466, h: 50, mass: 23300}, {
    centerX: 272,
    centerY: 2200,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 305, centerY: 1129, w: 298, h: 50, mass: 14900}, {centerX: 768, centerY: 1067, w: 432, h: 50, mass: 21600}, {
    centerX: 821,
    centerY: 731,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 765, centerY: 2250, w: 563, h: 50, mass: 28150}, {centerX: 758, centerY: 270, w: 439, h: 50, mass: 21950}, {
    centerX: 345,
    centerY: 1416,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 456, centerY: 1837, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 1000,
  containerH: 2565,
  data: [{centerX: 707, centerY: 144, w: 536, h: 50, mass: 26800}, {centerX: 705, centerY: 732, w: 403, h: 50, mass: 20150}, {
    centerX: 733,
    centerY: 1179,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 299, centerY: 317, w: 525, h: 50, mass: 26250}, {centerX: 372, centerY: 1927, w: 470, h: 50, mass: 23500}, {
    centerX: 327,
    centerY: 1264,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 479, centerY: 1494, w: 615, h: 50, mass: 30750}, {centerX: 375, centerY: 548, w: 373, h: 50, mass: 18650}, {
    centerX: 710,
    centerY: 1722,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 725, centerY: 468, w: 302, h: 50, mass: 15100}, {centerX: 368, centerY: 2464, w: 466, h: 50, mass: 23300}, {
    centerX: 660,
    centerY: 2373,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 300, centerY: 1711, w: 298, h: 50, mass: 14900}, {centerX: 713, centerY: 2072, w: 432, h: 50, mass: 21600}, {
    centerX: 723,
    centerY: 289,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 345, centerY: 2223, w: 563, h: 50, mass: 28150}, {centerX: 284, centerY: 50, w: 439, h: 50, mass: 21950}, {
    centerX: 480,
    centerY: 974,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 283, centerY: 751, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 900,
  containerH: 2850,
  data: [{centerX: 526, centerY: 2750, w: 536, h: 50, mass: 26800}, {centerX: 289, centerY: 1015, w: 403, h: 50, mass: 20150}, {
    centerX: 276,
    centerY: 1665,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 418, centerY: 803, w: 525, h: 50, mass: 26250}, {centerX: 471, centerY: 270, w: 470, h: 50, mass: 23500}, {
    centerX: 592,
    centerY: 2515,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 545, centerY: 590, w: 615, h: 50, mass: 30750}, {centerX: 598, centerY: 1761, w: 373, h: 50, mass: 18650}, {
    centerX: 321,
    centerY: 427,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 640, centerY: 1074, w: 302, h: 50, mass: 15100}, {centerX: 490, centerY: 1490, w: 466, h: 50, mass: 23300}, {
    centerX: 277,
    centerY: 116,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 284, centerY: 1934, w: 298, h: 50, mass: 14900}, {centerX: 287, centerY: 2645, w: 432, h: 50, mass: 21600}, {
    centerX: 614,
    centerY: 1964,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 435, centerY: 1277, w: 563, h: 50, mass: 28150}, {centerX: 317, centerY: 2361, w: 439, h: 50, mass: 21950}, {
    centerX: 487,
    centerY: 2167,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 659, centerY: 119, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 800,
  containerH: 3206,
  data: [{centerX: 464, centerY: 498, w: 536, h: 50, mass: 26800}, {centerX: 500, centerY: 92, w: 403, h: 50, mass: 20150}, {
    centerX: 490,
    centerY: 1369,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 423, centerY: 1048, w: 525, h: 50, mass: 26250}, {centerX: 310, centerY: 183, w: 470, h: 50, mass: 23500}, {
    centerX: 406,
    centerY: 832,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 404, centerY: 1785, w: 615, h: 50, mass: 30750}, {centerX: 294, centerY: 2946, w: 373, h: 50, mass: 18650}, {
    centerX: 551,
    centerY: 3052,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 290, centerY: 3114, w: 302, h: 50, mass: 15100}, {centerX: 378, centerY: 2016, w: 466, h: 50, mass: 23300}, {
    centerX: 372,
    centerY: 1555,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 332, centerY: 657, w: 298, h: 50, mass: 14900}, {centerX: 346, centerY: 2336, w: 432, h: 50, mass: 21600}, {
    centerX: 510,
    centerY: 2185,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 422, centerY: 2778, w: 563, h: 50, mass: 28150}, {centerX: 355, centerY: 332, w: 439, h: 50, mass: 21950}, {
    centerX: 407,
    centerY: 2554,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 306, centerY: 1230, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 700,
  containerH: 3664,
  data: [{centerX: 350, centerY: 248, w: 536, h: 50, mass: 26800}, {centerX: 333, centerY: 3459, w: 403, h: 50, mass: 20150}, {
    centerX: 368,
    centerY: 3572,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 350, centerY: 1812, w: 525, h: 50, mass: 26250}, {centerX: 349, centerY: 2030, w: 470, h: 50, mass: 23500}, {
    centerX: 350,
    centerY: 477,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 350, centerY: 738, w: 615, h: 50, mass: 30750}, {centerX: 336, centerY: 2982, w: 373, h: 50, mass: 18650}, {
    centerX: 339,
    centerY: 2426,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 361, centerY: 2815, w: 302, h: 50, mass: 15100}, {centerX: 349, centerY: 1592, w: 466, h: 50, mass: 23300}, {
    centerX: 347,
    centerY: 3302,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 349, centerY: 105, w: 298, h: 50, mass: 14900}, {centerX: 359, centerY: 1382, w: 432, h: 50, mass: 21600}, {
    centerX: 323,
    centerY: 1209,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 352, centerY: 1008, w: 563, h: 50, mass: 28150}, {centerX: 357, centerY: 2236, w: 439, h: 50, mass: 21950}, {
    centerX: 350,
    centerY: 2632,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 371, centerY: 3141, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 600,
  containerH: 4275,
  data: [{centerX: 300, centerY: 3100, w: 536, h: 50, mass: 26800}, {centerX: 299, centerY: 234, w: 403, h: 50, mass: 20150}, {
    centerX: 300,
    centerY: 3681,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 300, centerY: 105, w: 525, h: 50, mass: 26250}, {centerX: 298, centerY: 1934, w: 470, h: 50, mass: 23500}, {
    centerX: 300,
    centerY: 1191,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 318, centerY: 513, w: 615, h: 50, mass: 30750}, {centerX: 298, centerY: 2525, w: 373, h: 50, mass: 18650}, {
    centerX: 299,
    centerY: 2791,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 294, centerY: 685, w: 302, h: 50, mass: 15100}, {centerX: 300, centerY: 3920, w: 466, h: 50, mass: 23300}, {
    centerX: 300,
    centerY: 3422,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 298, centerY: 999, w: 298, h: 50, mass: 14900}, {centerX: 299, centerY: 1682, w: 432, h: 50, mass: 21600}, {
    centerX: 298,
    centerY: 365,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 300, centerY: 1439, w: 563, h: 50, mass: 28150}, {centerX: 300, centerY: 4132, w: 439, h: 50, mass: 21950}, {
    centerX: 344,
    centerY: 2239,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 304, centerY: 840, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 500,
  containerH: 5130,
  data: [{centerX: 278, centerY: 2437, w: 536, h: 50, mass: 26800}, {centerX: 246, centerY: 1415, w: 403, h: 50, mass: 20150}, {
    centerX: 248,
    centerY: 1954,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 273, centerY: 4416, w: 525, h: 50, mass: 26250}, {centerX: 249, centerY: 134, w: 470, h: 50, mass: 23500}, {
    centerX: 283,
    centerY: 1698,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 318, centerY: 777, w: 615, h: 50, mass: 30750}, {centerX: 249, centerY: 317, w: 373, h: 50, mass: 18650}, {
    centerX: 246,
    centerY: 525,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 250, centerY: 3926, w: 302, h: 50, mass: 15100}, {centerX: 250, centerY: 2987, w: 466, h: 50, mass: 23300}, {
    centerX: 250,
    centerY: 3645,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 248, centerY: 2176, w: 298, h: 50, mass: 14900}, {centerX: 248, centerY: 4712, w: 432, h: 50, mass: 21600}, {
    centerX: 249,
    centerY: 4156,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 292, centerY: 4963, w: 563, h: 50, mass: 28150}, {centerX: 250, centerY: 3319, w: 439, h: 50, mass: 21950}, {
    centerX: 344,
    centerY: 1115,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 249, centerY: 2702, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 400,
  containerH: 6413,
  data: [{centerX: 278, centerY: 1086, w: 536, h: 50, mass: 26800}, {centerX: 212, centerY: 1726, w: 403, h: 50, mass: 20150}, {
    centerX: 198,
    centerY: 2792,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 273, centerY: 2519, w: 525, h: 50, mass: 26250}, {centerX: 245, centerY: 2215, w: 470, h: 50, mass: 23500}, {
    centerX: 283,
    centerY: 113,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 318, centerY: 380, w: 615, h: 50, mass: 30750}, {centerX: 198, centerY: 1957, w: 373, h: 50, mass: 18650}, {
    centerX: 199,
    centerY: 5291,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 200, centerY: 3934, w: 302, h: 50, mass: 15100}, {centerX: 243, centerY: 597, w: 466, h: 50, mass: 23300}, {
    centerX: 233,
    centerY: 1496,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 200, centerY: 5753, w: 298, h: 50, mass: 14900}, {centerX: 226, centerY: 3519, w: 432, h: 50, mass: 21600}, {
    centerX: 190,
    centerY: 234,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 292, centerY: 4804, w: 563, h: 50, mass: 28150}, {centerX: 230, centerY: 3116, w: 439, h: 50, mass: 21950}, {
    centerX: 344,
    centerY: 831,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 195, centerY: 1286, w: 302, h: 50, mass: 15100}],
}, {
  containerW: 300,
  containerH: 8550,
  data: [{centerX: 278, centerY: 7488, w: 536, h: 50, mass: 26800}, {centerX: 212, centerY: 5397, w: 403, h: 50, mass: 20150}, {
    centerX: 170,
    centerY: 6161,
    w: 320,
    h: 50,
    mass: 16000,
  }, {centerX: 273, centerY: 4003, w: 525, h: 50, mass: 26250}, {centerX: 245, centerY: 3644, w: 470, h: 50, mass: 23500}, {
    centerX: 283,
    centerY: 607,
    w: 545,
    h: 50,
    mass: 27250,
  }, {centerX: 318, centerY: 4343, w: 615, h: 50, mass: 30750}, {centerX: 197, centerY: 6768, w: 373, h: 50, mass: 18650}, {
    centerX: 188,
    centerY: 5156,
    w: 356,
    h: 50,
    mass: 17800,
  }, {centerX: 161, centerY: 1241, w: 302, h: 50, mass: 15100}, {centerX: 243, centerY: 4912, w: 466, h: 50, mass: 23300}, {
    centerX: 233,
    centerY: 1875,
    w: 446,
    h: 50,
    mass: 22300,
  }, {centerX: 159, centerY: 5920, w: 298, h: 50, mass: 14900}, {centerX: 226, centerY: 221, w: 432, h: 50, mass: 21600}, {
    centerX: 150,
    centerY: 8256,
    w: 235,
    h: 50,
    mass: 11750,
  }, {centerX: 292, centerY: 3111, w: 563, h: 50, mass: 28150}, {centerX: 230, centerY: 4644, w: 439, h: 50, mass: 21950}, {
    centerX: 344,
    centerY: 5684,
    w: 667,
    h: 50,
    mass: 33350,
  }, {centerX: 161, centerY: 6425, w: 302, h: 50, mass: 15100}],
}]

export function getTagPosDataCache(containerW: number): TagPosCacheItem {
  for (let i = 0; i < tagPos.length; i++) {
    if (containerW > tagPos[i].containerW) {
      return produce(tagPos[i], draft => {
        for (let j = 0; j < draft.data.length; j++) {
          draft.data[j].centerX += (containerW - tagPos[i].containerW) / 2
        }
      })
    }
  }
  return tagPos[tagPos.length - 1]
}