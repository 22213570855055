import { h } from 'preact'
import {PropsWithChildren} from '../utils'

export default function Button(props: PropsWithChildren<{ href: string | null, className?: string }>) {
  return <a
    href={props.href ?? ''}
    className={`px-4 py-2 rounded shadow m-2 active:scale-95 active:brightness-90 cursor-alias ${props.href === null ? 'cursor-not-allowed' : ''} ${props.className ?? ''}`}
    target={'_blank'} rel={'noreferrer'}>
        <span className={''}>
          {props.children}
        </span>
  </a>
}
