import Icon from '@mdi/react'
import {mdiAdvertisementsOff, mdiAllInclusive, mdiEmoticonHappyOutline, mdiServer} from '@mdi/js'
import {PropsWithClassName} from '../../../../utils'
import {h} from 'preact'

function GridItem(props: { icon: string, text: string }) {
  return <p className={'text-lg'}>
    <Icon path={props.icon}
          size={'2rem'}
          className={'inline mr-2 xs:mr-1'}/>
    {props.text}
  </p>
}

export function FeaturesGrid(props:PropsWithClassName<{}>) {
  return <div className={`grid grid-cols-1 xs:grid-cols-2 gap-2 opacity-99 ${props.className??''}`}>
    <GridItem icon={mdiAdvertisementsOff} text={'No login, no ads, no bs'}/>
    <GridItem icon={mdiServer} text={'Completely serverless'}/>
    <GridItem icon={mdiAllInclusive} text={'Unlimited players'}/>
    <GridItem icon={mdiEmoticonHappyOutline} text={'Fun!'}/>
  </div>
}