import {useInterval} from 'react-use'
import {useAtom} from 'jotai'
import {bodyHeightState} from '../utils'

export function BodyHeightUpdater() {
  const [, setBodyHeight] = useAtom(bodyHeightState)
  useInterval(() => {
      if (document?.body?.clientHeight) {
        setBodyHeight(document.body.clientHeight)
      }
    },
    250,
  )
  return null
}