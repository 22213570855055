import {useMemo, useState} from 'preact/compat'
import {finishColors, startColors} from '../../../utils'
import {AnimatePresence, m} from 'framer-motion'
import { h } from 'preact'

export default function HiThereSection() {
  const {
    keyFrameTimes,
    startKeyFrames,
    finishKeyFrames,
    duration,
  } = useMemo(() => {
    const colors = startColors.length
    const transPercent = 0.4
    const x = 1 / (colors * (transPercent + 1))
    const keyFrameTimes = []
    for (let i = 0; i < colors; i++) {
      keyFrameTimes.push(i * transPercent * x + i * x, i * transPercent * x + (i + 1) * x)
    }
    keyFrameTimes.push(colors * (transPercent + 1) * x)
    const startKeyFrames = []
    const finishKeyFrames = []
    for (let i = 0; i < colors; i++) {
      startKeyFrames.push(startColors[i], startColors[i])
      finishKeyFrames.push(finishColors[i], finishColors[i])
    }
    startKeyFrames.push(startColors[0])
    finishKeyFrames.push(finishColors[0])

    return {
      keyFrameTimes,
      startKeyFrames,
      finishKeyFrames,
      duration: colors * 2.5,
    }
  }, [])


  return <div className={'h-screen flex flex-col justify-center items-center text-center'}>
    <p className={'text-4xl sm:text-5xl md:text-6xl italic'}>Hi There,</p>
    <p className={'text-5xl sm:text-7xl md:text-8xl mt-4 italic'}>
      I&apos;m{' '}
      <m.span
        className={'font-bold'}
        style={{
          background: 'linear-gradient(to right, var(--start) 0%, var(--finish) 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        initial={{
          '--start': startKeyFrames[0],
          '--finish': finishKeyFrames[0],
        } as any}
        animate={{
          '--start': startKeyFrames,
          '--finish': finishKeyFrames,
        } as any}
        transition={{duration, times: keyFrameTimes, repeat: Infinity}}>
        Pegasis
      </m.span>
      .
    </p>
    <Subtitle/>
  </div>
}

function Subtitle() {
  const [showToolTip, setShowToolTip] = useState(false)
  const [isAnimatingToolTip, setIsAnimatingToolTip] = useState(false)

  return <p className={'mt-8 md:mt-12 text-gray-500'}>
    /&nbsp;NOT your average{' '}
    <m.span
      className={'relative border-dashed border-b-2 border-gray-400'}
      onMouseEnter={() => {
        setShowToolTip(true)
      }}
      onMouseLeave={() => {
        setShowToolTip(false)
      }}
      onClick={() => setShowToolTip(true)}>
        <span>
          university student
        </span>
      <AnimatePresence>
        {showToolTip ? <m.span
          initial={{opacity: 0, translateY: '-25%'}}
          animate={{opacity: 1, translateY: '0%'}}
          exit={{opacity: 0, translateY: '-25%'}}
          onAnimationStart={() => setIsAnimatingToolTip(true)}
          onAnimationComplete={() => setIsAnimatingToolTip(false)}
          style={{translateX: '-50%'}}
          transition={{type: 'spring', bounce: 0, duration: 0.2}}
          className={`absolute left-0 xs:left-1/2 top-full flex flex-col items-center ${isAnimatingToolTip ? 'pointer-events-none' : ''}`}>
          <span className={'triangle'}/>
          <span className={'bg-gray-300 text-gray-900 px-2 py-1 rounded whitespace-nowrap'}>
              Studying{' '}
            <a href={'https://www.eng.mcmaster.ca/programs/engineering-i'} target={'_blank'} className={'underline cursor-alias'} rel={'noreferrer'}>
              Engineering I @ Mcmaster
            </a>
          </span>
        </m.span> : null}
      </AnimatePresence>
    </m.span>
    .&nbsp;/
  </p>
}