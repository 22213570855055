import {m} from 'framer-motion'
import {PropsWithChildren, range} from '../../../../utils'
import Icon from '@mdi/react'
import {mdiCellphone, mdiNumeric1CircleOutline, mdiNumeric2CircleOutline, mdiNumeric3CircleOutline, mdiRaspberryPi, mdiServerNetwork} from '@mdi/js'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import {Fragment, h} from 'preact'

function TransferLink() {
  const duration = 2
  const highlights = 3
  return <div
    className={'absolute w-full h-full overflow-hidden'}>
    {range(highlights).map(i => <m.div
      key={i}
      className={`w-20 h-1 bg-gradient-to-r from-[rgba(37,99,235,0)] via-blue-400 to-[rgba(37,99,235,0)] absolute -translate-x-1/2`}
      initial={{left: '-15%'}}
      animate={{left: '130%'}}
      transition={{duration, delay: duration / highlights * i, type: 'tween', ease: 'linear', repeat: Infinity}}/>)}
  </div>
}

function Annotation(props: PropsWithChildren<{ icon: string, bottomAlign?: boolean, unlimitedTextWidth?: boolean }>) {
  return <Fragment>
    <div
      className={`text-center text-xl flex flex-col items-center absolute ${props.unlimitedTextWidth ? 'left-[-5.5rem] right-[-5.5rem]' : 'left-0 right-0'} ${props.bottomAlign ? 'bottom-[-6rem]' : 'top-[-6rem]'} z-10`}>
      <Icon path={props.icon}
            size={'2.5rem'}
            className={'inline mt-2 mb-1'}/>
      <p className={props.bottomAlign ? 'mb-3' : ''}>{props.children}</p>
    </div>
  </Fragment>
}

function TechStack(props: PropsWithChildren<{ icon: string }>) {
  return <div className={'flex-none z-10 mx-4 flex flex-col items-center border-2 rounded-xl p-2 pt-1 border-gray-400'}>
    <Icon className={'text-gray-700'} path={props.icon} size={'4rem'}/>
    {props.children}
  </div>
}

export default function CommunicationDiagram() {
  return <div className={'flex justify-center items-stretch my-[7.5rem] mx-[-0.25rem] w-full'}>
    <TechStack icon={mdiServerNetwork}>
      <TechnologyTag data={TechnologyTags.nginx} className={'bg-black bg-opacity-10 active:bg-opacity-20'}/>
      <TechnologyTag data={TechnologyTags.cloudflare} className={'bg-black bg-opacity-10 active:bg-opacity-20'}/>
    </TechStack>

    <div className={'w-full flex-shrink flex items-center relative'}>
      <Annotation icon={mdiNumeric1CircleOutline}>
        App downloads the latest agent from the server.
      </Annotation>
      <div className={'relative w-full h-1 bg-black bg-opacity-10 z-10'}>
        <TransferLink/>
      </div>
    </div>

    <TechStack icon={mdiCellphone}>
      <TechnologyTag data={TechnologyTags.dart} className={'bg-black bg-opacity-10 active:bg-opacity-20'}/>
      <TechnologyTag data={TechnologyTags.flutter} className={'bg-black bg-opacity-10 active:bg-opacity-20'}/>
    </TechStack>

    <div className={'w-full flex-shrink flex items-center relative'}>
      <Annotation icon={mdiNumeric2CircleOutline}>
        App uploads the agent to <code>/tmp</code> using sftp.
      </Annotation>
      <Annotation icon={mdiNumeric3CircleOutline} bottomAlign unlimitedTextWidth>
        App retrieves status from the agent using ssh.
      </Annotation>
      <div className={'relative w-full h-1 bg-black bg-opacity-10 z-10'}>
        <TransferLink/>
      </div>
    </div>

    <div className={'flex items-center flex-none'}>
      <TechStack icon={mdiRaspberryPi}>
        <TechnologyTag data={TechnologyTags.rust} className={'bg-black bg-opacity-10 active:bg-opacity-20'}/>
      </TechStack>
    </div>
  </div>
}