import WideSideBar from './WideSideBar'
import {Media} from '../../../media'
import TopBar from './TopBar'
import {atom, useAtom } from 'jotai'
import {librariesTitleRefState} from '../sections/LibrariesSection'
import {bodyHeightState} from '../../../utils'
import {taProjectCardRefState} from '../projects/ta/TeachAssistProjectCard'
import {contributionsTitleRefState} from '../sections/contributionsSection/ContributionsSection'
import {useWindowScroll} from 'react-use'
import {serversTitleRefState} from '../sections/ServersSection'
import {useAtomValue} from 'jotai/utils'
import {Fragment, h, RefObject} from 'preact'
import {useEffect, useMemo} from 'preact/compat'

export const navBarSelectState = atom(0)

export type NavTitles = Array<{ text: string, href: string, ref: RefObject<HTMLDivElement> | null }>

export default function NavBar() {
  const taProjectCardRef = useAtomValue(taProjectCardRefState)
  const librariesTitleRef = useAtomValue(librariesTitleRefState)
  const contributionsTitleRef = useAtomValue(contributionsTitleRefState)
  const serversTitleRef = useAtomValue(serversTitleRefState)
  const bodyHeight = useAtomValue(bodyHeightState)
  const [, setSelectedI] = useAtom(navBarSelectState)
  const windowScroll = useWindowScroll()

  const titles = useMemo(() => [
    {
      text: 'Apps & Websites',
      href: '#apps_websites',
      ref: taProjectCardRef,
    },
    {
      text: 'Libraries',
      href: '#libraries',
      ref: librariesTitleRef,
    },
    {
      text: 'Contributions',
      href: '#contributions',
      ref: contributionsTitleRef,
    },
    {
      text: 'Servers',
      href: '#servers',
      ref: serversTitleRef,
    },
  ], [contributionsTitleRef, librariesTitleRef, serversTitleRef, taProjectCardRef])

  useEffect(() => {
    for (let i = titles.length - 1; i >= 0; i--) {
      const {ref} = titles[i]
      const boundingTop = ref?.current?.getBoundingClientRect()?.top
      if (boundingTop) {
        if (((window.innerHeight - boundingTop) / window.innerHeight) > 0.3) {
          setSelectedI(i)
          break
        }
      }
    }
  }, [titles, setSelectedI, bodyHeight, windowScroll])

  return <Fragment>
    <Media greaterThanOrEqual={'2xl'}>
      <WideSideBar titles={titles}/>
    </Media>
    <Media lessThan={'2xl'}>
      <TopBar titles={titles}/>
    </Media>
  </Fragment>
}
