import './styles/globals.css'
import './styles/idea.css'
import {h} from 'preact'
import {domAnimation, LazyMotion} from 'framer-motion'
import HiThereSection from './pages/index/sections/HiThereSection'
import ImSection from './pages/index/sections/ImSection'
import {BodyHeightUpdater} from './components/BodyHeightUpdater'
import NavBar from './pages/index/navbar/NavBar'
import ServersSection from './pages/index/sections/ServersSection'
import ContributionsSection from './pages/index/sections/contributionsSection/ContributionsSection'
import ProjectsSection from './pages/index/sections/ProjectsSection'
import LibrariesSection from './pages/index/sections/LibrariesSection'
import {useEffectOnce} from 'react-use'
import smoothscroll from 'smoothscroll-polyfill'
import {MediaContextProvider} from './media'

export default function App() {
  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      smoothscroll.polyfill()
    }
  })

  return <LazyMotion features={domAnimation}>
    <MediaContextProvider>
      <div className={'flex flex-col items-center pb-[100vh]'}>
        <HiThereSection/>
        <ImSection/>
        <NavBar/>
        <ProjectsSection/>
        <LibrariesSection/>
        <ContributionsSection/>
        <ServersSection/>
        <BodyHeightUpdater/>
      </div>
    </MediaContextProvider>
  </LazyMotion>
}
