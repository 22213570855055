import ProjectCard from '../ProjectCard'
import videoFirstFrame from './videoFirstFrame.jpg'
import LazyVideo from '../../../../components/LazyVideo'
import Button from '../../../../components/Button'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import { h } from 'preact'

export default function DistributionCalculatorProjectCard() {
  return <ProjectCard className={'p-6'}>
    <p className={'text-2xl font-bold'}>Distribution Calculator</p>
    <p className={'text-gray-600'}>Because I got bored of drawing graphs by hand.</p>
    <div className={'flex items-center'}>
      <TechnologyTag data={TechnologyTags.dart} className={'bg-gray-200'} spacing={'px-4 py-2 mr-2 mt-2'}/>
      <TechnologyTag data={TechnologyTags.flutter} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2'}/>
    </div>
    <LazyVideo w={950} h={630} src={'/videos/distributionCalculator.mp4'} loadingImage={videoFirstFrame} className={'mt-2 rounded overflow-hidden shadow'}/>

    <div className={'flex mt-4 -ml-2 -mb-2'}>
      <Button href={'https://dev.pegas.is/distribution_calculator/'} className={'bg-[#008577] text-white'}>
        Website
      </Button>
    </div>
  </ProjectCard>
}