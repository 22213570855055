import {PropsWithClassName} from '../utils'
import {Media} from '../media'
import {ComponentChildren, Fragment, h} from 'preact'

export default function FeaturedNumbers(props: PropsWithClassName<{ data: Array<{ number: ComponentChildren, text: ComponentChildren }> }>) {
  return <>
    <Media greaterThanOrEqual={'510px'} className={`flex flex-col items-center xl:items-start ${props.className}`}>
      {props.data.map((d, i) => <p key={i} className={i === 0 ? '' : 'mt-2'}>
        <span className={'font-bold italic text-3xl sm:text-4xl'}>
          {d.number}{' '}
        </span>
        {d.text}
      </p>)}
    </Media>
    <Media lessThan={'510px'} className={`flex flex-col items-center text-center ${props.className}`}>
      {props.data.map((d, i) => <Fragment key={i}>
        <p className={`font-bold italic text-3xl sm:text-4xl ${i === 0 ? '' : 'mt-2'}`}>
          {d.number}{' '}
        </p>
        <p>
          {d.text}
        </p>
      </Fragment>)}
    </Media>
  </>
}