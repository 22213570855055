import {h} from 'preact'
import {PropsWithClassName} from '../utils'

function getLast<T>(arr: T[]): T {
  return arr[arr.length - 1]
}

const mimeMap: Record<string, string> = {
  webp: 'image/webp',
  jpg: 'image/jpeg',
  png: 'image/png',
  avif: 'image/avif',
}

export default function Image(props: PropsWithClassName<{ src: string | string[], alt?: string, priority?: boolean, lazyBoundary?: string }>) {
  if (typeof props.src === 'string') {
    return <img src={props.src} alt={props.alt ?? ''} className={`w-full ${props.className}`}/>
  } else {
    return <picture>
      {props.src.map(src => <source key={src} srcSet={src} type={mimeMap[getLast(src.split('.'))]}/>)}
      <img src={getLast(props.src)} alt={props.alt ?? ''}/>
    </picture>
  }
}