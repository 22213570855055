import { h } from 'preact'
import {useInViewEffect} from 'react-hook-inview'
import {PropsWithClassName} from '../utils'
import {useState} from 'preact/compat'

export type LazyVideoProps = PropsWithClassName<{
    w: number,
    h: number,
    src: string,
    loadingImage: string,
}>

export default function LazyVideo(props: LazyVideoProps) {
    const [showVideo, setShowVideo] = useState(false)
    const ref = useInViewEffect(([entry], observer) => {
        if (entry.isIntersecting) {
            observer.unobserve(entry.target)
            setShowVideo(true)
        }
    }, {threshold: 0}, [])

    return <div ref={ref}
                className={`relative w-full ${props.className ?? ''}`}
                style={{paddingTop: `${props.h / props.w * 100}%`}}>
        {showVideo ?
            <video className={'absolute left-0 top-0 w-full'} poster={props.loadingImage} muted autoPlay playsInline loop>
                <source src={props.src} type={'video/mp4'}/>
            </video> : null}
        <noscript>
            <video className={'absolute left-0 top-0 w-full'} poster={props.loadingImage} muted autoPlay playsInline loop>
                <source src={props.src} type={'video/mp4'}/>
            </video>
        </noscript>
    </div>
}