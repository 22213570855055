import TyporaParserCard from '../projects/typoraParser/TyporaParserCard'
import {atom, useAtom} from 'jotai'
import ImmuKtCard from '../projects/immukt/ImmuKtCard'
import {KeepKeyboardPopupCard} from '../projects/flutterKeepKeyboardPopup/KeepKeyboardPopupCard'
import {KeyboardAwareDialogCard} from '../projects/flutterKeyboardAwareDialog/KeyboardAwareDialogCard'
import Divider from '../../../components/Divider'
import {h, RefObject} from 'preact'
import {useEffect, useRef} from 'preact/compat'

const librariesTitleRefState = atom<RefObject<HTMLDivElement>>({current: null})

export {librariesTitleRefState}

export default function LibrariesSection() {
  const ref = useRef<HTMLDivElement>(null)
  const [, setTitleRef] = useAtom(librariesTitleRefState)
  useEffect(() => {
    setTitleRef(ref)
  }, [ref, setTitleRef])

  return <div className={'sm:px-8 md:px-16 2xl:pl-80 w-full flex flex-col items-center'}>
    <Divider id={'libraries'} ref={ref} text={'Libraries'} gradientColorI={1}/>
    <div className={'grid justify-items-center grid-cols-1 xl:grid-cols-2 w-full max-w-[46rem] xl:max-w-screen-2xl gap-x-16 gap-y-8 md:gap-y-16 mt-16'}>
      <TyporaParserCard/>
      <ImmuKtCard/>
      <KeepKeyboardPopupCard/>
      <KeyboardAwareDialogCard/>
    </div>
  </div>
}
