
import {ContributionData, contributionsData} from './contributionsData'
import {getTagPosDataCache, TagPos} from './TagPos'
import {MotionValue} from 'framer-motion/types/value'
import {useWindowSize} from 'react-use'
import {m, useTransform, useViewportScroll} from 'framer-motion'
import Image from '../../../../components/Image'
import {prLogo} from '../../../../icons/icons'
import {calculateTagsPos} from './tagPosCalculation'
import {useInView} from 'react-hook-inview'
import {Fragment, h} from 'preact'
import {forwardRef, useCallback, useLayoutEffect, useRef, useState} from 'preact/compat'

const calculateTags = false

const ContributionTag = forwardRef<HTMLAnchorElement, { data: ContributionData, tagPos: TagPos, containerW: number, containerH: number, scrolledHeight: MotionValue<number> }>((props, ref) => {
  const {height: windowHeight} = useWindowSize()

  const r = 0.75 * windowHeight
  const alpha = Math.acos(windowHeight / (2 * r))
  const theta = useTransform(props.scrolledHeight, s => (s - props.tagPos.centerY) / r)

  const x = useTransform(theta, t => r * Math.sin(t + alpha))
  const translateZ = useTransform(x, v => -(r - v))
  const opacity = useTransform(translateZ, [-10, -170], [1, 0])

  const top = useTransform(props.scrolledHeight, scrolledHeight => props.tagPos.centerY - props.tagPos.h / 2 - scrolledHeight + windowHeight)

  return <m.a
    ref={ref} href={props.data.href}
    style={{
      left: `${props.tagPos.centerX - props.tagPos.w / 2}px`,
      top,
      transformStyle: 'preserve-3d',
      translateZ,
      opacity,
    }}
    target={'_blank'} rel={'noreferrer'}
    className={`absolute group rounded-full bg-gray-200 active:brightness-90 flex items-center cursor-alias pointer-events-auto`}>
    <div className={'w-12 h-12 bg-gray-300 rounded-full overflow-hidden'}>
      <Image lazyBoundary={`${props.containerH}px`} src={props.data.image ?? prLogo}/>
    </div>
    <div className={`ml-2 mr-6`}>
      <p className={'text-gray-600 text-sm mt-0.5'}>
        {props.data.projectName}
        #{props.data.prNumber}
      </p>
      <p className={'text-xl group-hover:underline decoration-gray-450'}>
        {props.data.prName}
      </p>
    </div>
  </m.a>
})

export default function TagsCylinder() {
  const {width: windowW} = useWindowSize()

  const containerRef = useRef<HTMLDivElement>()
  const [inViewRef, isInView] = useInView({threshold: 0}, [])
  const setContainerRefs = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      containerRef.current = node
      inViewRef(node)
    }
  }, [inViewRef])

  const [containerH, setContainerH] = useState(0)
  const [containerW, setContainerW] = useState(0)
  const [containerX, setContainerX] = useState(0)
  useLayoutEffect(() => {
    setContainerW(containerRef.current!.clientWidth)
    setContainerX(containerRef.current!.getBoundingClientRect().left)
  }, [setContainerW, windowW])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const tagRefs = contributionsData.map(() => useRef<HTMLAnchorElement>(null))
  const [tagPosDataList, setTagPosDataList] = useState<TagPos[]>(tagRefs.map(() => ({
    centerX: 0,
    centerY: 0,
    w: 0,
    h: 0,
    mass: 0,
  })))

  // if calculateTags, calculate tag pos every time
  useLayoutEffect(() => {
    if (calculateTags) {
      const cache = []
      for (let w = 1500; w > 200; w -= 100) {
        cache.push(calculateTagsPos(w, tagRefs))
      }
      console.log(JSON.stringify(cache))

      const {data, containerH} = calculateTagsPos(containerRef.current!.clientWidth, tagRefs)
      setTagPosDataList(data)
      setContainerH(containerH)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // if not calculateTags, read from cache
  useLayoutEffect(() => {
    if (!calculateTags) {
      const {data, containerH} = getTagPosDataCache(containerRef.current!.clientWidth)
      setTagPosDataList(data)
      setContainerH(containerH)
    }
  }, [windowW])

  const {scrollY} = useViewportScroll()
  const scrolledHeight = useTransform(scrollY, () => window.innerHeight - (containerRef.current?.getBoundingClientRect()?.top ?? 0))

  return <Fragment>
    <div ref={setContainerRefs} className={'w-full max-w-screen-2xl mt-8 relative overflow-hidden'} style={{height: `${containerH}px`}}/>
    {isInView ? <div className={'fixed top-0 bottom-0 pointer-events-none'} style={{left: containerX, width: containerW, perspective: '500px'}}>
      {contributionsData.map((data, i) =>
        <ContributionTag
          key={data.href}
          ref={tagRefs[i]}
          containerW={containerW}
          containerH={containerH}
          scrolledHeight={scrolledHeight}
          data={data}
          tagPos={tagPosDataList[i]}/>)}
    </div> : null}
  </Fragment>
}