import ProjectCard from '../ProjectCard'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import {PropsWithChildren, PropsWithClassName} from '../../../../utils'
import Button from '../../../../components/Button'
import {InDevelopmentBanner} from '../InDevelopmentBanner'
import {Media} from '../../../../media'
import {Fragment, h} from 'preact'

function HighLightKotlin(props: PropsWithChildren<PropsWithClassName<{}>>) {
  return <pre className={`overflow-x-auto ${props.className ?? ''}`}>
    <code dangerouslySetInnerHTML={{__html: props.children as string}}/>
  </pre>
}

function Title() {
  return <Fragment>
    <Media greaterThanOrEqual={'xs'}>
      <p className={'text-4xl'}>
        ImmuKt{' '}
        <span className={'text-base text-gray-600'}>
          (Heavily inspired by <a className={'underline'} href={'https://immerjs.github.io/immer/'}>Immer</a>)
        </span>
      </p>
    </Media>
    <Media lessThan={'xs'}>
      <p className={'text-4xl'}>
        ImmuKt
      </p>
      <p className={'text-base text-gray-600 mt-1'}>
        (Heavily inspired by <a className={'underline'} href={'https://immerjs.github.io/immer/'}>Immer</a>)
      </p>
    </Media>
  </Fragment>
}

export default function ImmuKtCard() {
  return <ProjectCard className={'p-6 flex flex-col items-start'}>
    <InDevelopmentBanner/>
    <Title/>
    <p className={'text-gray-600 mt-1'}>
      Create the next immutable state tree by simply modifying the current tree.
    </p>
    <TechnologyTag data={TechnologyTags.kotlin} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2'}/>

    <p className={'text-lg font-bold mt-4'}>From.....</p>
    <HighLightKotlin className={'kotlin text-sm bg-gray-100 py-2 px-4 rounded w-full'}>
      {`<span class="hljs-keyword">val</span> newList = ArrayList&lt;Person&gt;()
newList.addAll(data.<span class="hljs-variable">people</span>)
newList[<span class="hljs-number">0</span>] = Person(
    newList[<span class="hljs-number">0</span>].<span class="hljs-variable">name</span>,
    <span class="hljs-number">19</span>,
)
<span class="hljs-keyword">val</span> newData = Party(
    data.<span class="hljs-variable">time</span>,
    newList,
)`}
    </HighLightKotlin>

    <p className={'text-lg font-bold mt-2'}>To...</p>
    <HighLightKotlin className={'kotlin text-sm bg-gray-100 py-2 px-4 rounded w-full'}>
      {`<span class="hljs-keyword">val</span> newData = data.<span style="color: #00672a">produceWith</span> {
    it[Party::people][<span class="hljs-number">0</span>][Person::age] = <span class="hljs-number">19</span>
}`}
    </HighLightKotlin>

    <Button href={'https://github.com/PegasisForever/immukt'} className={'bg-[#2b66b8] text-white ml-0 mb-0 mt-6'}>
      Github
    </Button>
  </ProjectCard>
}