import ProjectCard from '../ProjectCard'
import {TechnologyTag, TechnologyTags} from '../../../../components/TechnologyCard'
import Button from '../../../../components/Button'
import {h} from 'preact'

export function KeepKeyboardPopupCard() {
  return <ProjectCard className={'p-6 flex flex-col items-start'}>
    <p className={'text-3xl'}>Keep Keyboard Popup Menu</p>
    <p className={'text-gray-600'}>
      Fixes an annoying Flutter bug where opening a popup will cause keyboard to close.
    </p>
    <div className={'flex'}>
      <TechnologyTag data={TechnologyTags.dart} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2 mr-2'}/>
      <TechnologyTag data={TechnologyTags.flutter} className={'bg-gray-200'} spacing={'px-4 py-2 mt-2'}/>
    </div>
    <div className={'flex mt-4 -ml-2 -mb-2'}>
      <Button href={'https://github.com/PegasisForever/keep_keyboard_popup_menu'} className={'bg-[#2b66b8] text-white'}>
        Github
      </Button>
      <Button href={'https://pub.dev/packages/keep_keyboard_popup_menu'} className={'bg-[#2b66b8] text-white'}>
        pub.dev
      </Button>
    </div>
  </ProjectCard>
}