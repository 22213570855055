import Image from '../../../../components/Image'
import {AnimatePresence, m} from 'framer-motion'
import pegasisLogo from './pegasis.jpg'
import alexLogo from './alex.png'
import produce from 'immer'
import {getRandomInt, PropsWithChildren, range, sleep} from '../../../../utils'
import worldMap from './worldMap.svg'
import pinIcon from './pin.svg'
import coordinates from './coordinates'
import profilePictures from './profilePictures/profilePictures'
import { h } from 'preact'
import {useEffect, useMemo, useState} from 'preact/compat'

type PollCardPropsType = PropsWithChildren<{ profilePicture: string, name: string, time: string, title: string, show: boolean }>

function PollCard(props: PollCardPropsType) {
  return <AnimatePresence>
    {props.show && <m.div
        className={'absolute w-full p-4 rounded'}
        style={{boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)'}}
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        exit={{opacity: 0}}>
        <div className={'flex items-center'}>
            <div className={'w-12 flex-none mr-4'}>
                <Image className={'rounded-full'} src={props.profilePicture}/>
            </div>

            <p className={'flex-grow text-lg'}>{props.name}</p>
            <p className={'flex-none text-gray-500'}>{props.time}</p>
        </div>
        <p className={'text-2xl mt-2'}>{props.title}</p>
      {props.children}
    </m.div>}
  </AnimatePresence>
}

// todo fix gap on 100% scale
function ProgressBar(props: { text: string, percentage: number }) {
  return <div className={'mt-1 h-8 rounded border-2 border-[#b4b7fa] relative'}>
    <m.div
      className={'absolute left-0 top-0 bottom-0 bg-[#b4b7fa] rounded-r'}
      animate={{width: `${isNaN(props.percentage) ? 0 : props.percentage}%`}}
      transition={{type: 'tween', duration: 0.3}}
    />
    <span className={'absolute left-2 top-[0.07rem]'}>{props.text}</span>
  </div>
}

export function TextPollCard(props: { show: boolean }) {
  const [votes, setVotes] = useState({
    totalVotes: 0,
    options: [
      {text: 'YRDSB Teach Assist', votes: 0},
      {text: 'Puno!', votes: 0},
      {text: 'GROK', votes: 0},
      {text: 'Raspberry Pi Monitor', votes: 0},
      {text: 'Decide 4 Me', votes: 0},
      {text: 'Pega Drive', votes: 0},
      {text: 'OW Guessr', votes: 0},
    ],
  })

  useEffect(() => {
    (async () => {
      await sleep(300)
      for (let i = 0; i < 10; i++) {
        await sleep(300)
        setVotes(old => produce(old, draft => {
          draft.totalVotes++
          draft.options[getRandomInt(draft.options.length)].votes++
        }))
      }
    })()
  }, [])

  return <PollCard show={props.show} profilePicture={pegasisLogo} name={'Pegasis'} time={'April 24, 17:17'} title={'What\'s the best project?'}>
    <div className={'mt-3'}>
      {votes.options.map(({text, votes: voteCount}, i) =>
        <ProgressBar key={i} text={`${text}: ${voteCount}`} percentage={voteCount / votes.totalVotes * 100}/>,
      )}
    </div>
  </PollCard>
}

function ImagePollPin(props: { x: number, y: number, profilePicture: string, delay: number }) {
  return <m.div
    className={'absolute w-[36px] h-[50.6] origin-bottom-center'}
    style={{
      left: `${props.x}%`,
      top: `${props.y}%`,
    }}
    initial={{
      translateX: '-50%',
      translateY: '-100%',
      opacity: 0,
      scale: 3,
    }}
    animate={{
      translateX: '-50%',
      translateY: '-100%',
      opacity: 1,
      scale: 1,
    }}
    transition={{delay: props.delay, duration: 0.5, type: 'spring'}}>
    <Image src={pinIcon}/>
    <div className={'w-[24px] h-[24px] absolute left-[6px] top-[7.5px]'}>
      <Image className={'rounded-full'} src={props.profilePicture}/>
    </div>
  </m.div>
}

function randomNoRepeats<T>(array: T[]): () => T {
  let copy = array.slice(0)
  return function (): T {
    if (copy.length < 1) {
      copy = array.slice(0)
    }
    const index = Math.floor(Math.random() * copy.length)
    const item = copy[index]
    copy.splice(index, 1)
    return item
  }
}

function shuffle<T>(array: T[]) {
  let currentIndex = array.length, randomIndex

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }
}

export function ImagePollCard(props: { show: boolean }) {
  const pins = useMemo(() => {
    shuffle(profilePictures)
    const chooser = randomNoRepeats(coordinates) as (() => { x: number, y: number })
    return range(10)
      .map(chooser)
      .map((coord, i) => ({
        coord,
        component: <ImagePollPin
          key={i}
          {...coord}
          delay={0.5 + i * 0.2}
          profilePicture={profilePictures[i]}
        />,
      }))
      .sort((a, b) => a.coord.y - b.coord.y)
      .map(it => it.component)
  }, [])

  return <PollCard show={props.show} profilePicture={alexLogo} name={'Alex Xu'} time={'April 24, 15:26'} title={'Where should I travel to?'}>
    <div className={'flex justify-center'}>
      <div className={'mt-3 w-full max-w-[34.6rem] relative'}>
        <Image src={worldMap} alt={'world map'}/>
        {pins}
      </div>
    </div>
  </PollCard>
}