import {finishColors, PropsWithChildren, startColors, useRefScrollProgress} from '../../../utils'
import {m, useTransform, useViewportScroll} from 'framer-motion'
import {Fragment, h} from 'preact'
import {useRef} from 'preact/compat'

type HighlightedNumberProps = PropsWithChildren<{
  startColor: string,
  finishColor: string,
}>

function HighlightedNumber(props: HighlightedNumberProps) {
  const ref = useRef<any>()
  const {start, end} = useRefScrollProgress(ref)
  const {scrollYProgress} = useViewportScroll()
  const scale = useTransform(scrollYProgress, [start, end], [1, 1.5])
  const backgroundPosition = useTransform(scrollYProgress, [start, end], ['0%', '100%'])

  return <m.span
    ref={ref}
    className={'font-bold italic inline-block'}
    style={{
      backgroundImage: `linear-gradient(135deg,#000,#000 45%,${props.startColor} 70%,${props.finishColor})`,
      backgroundSize: '500%',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      scale,
      backgroundPositionX: backgroundPosition,
      backgroundPositionY: backgroundPosition,
    }}>
    {props.children}
  </m.span>
}

type ImLineProps = {
  before: string,
  number: number,
  gradientIndex: number,
  after: string,
}

function ImLine(props: ImLineProps) {
  return <Fragment>
    <p className={'col-start-1 md:text-right mt-12 font-bold md:font-normal'}>{props.before}&nbsp;&nbsp;</p>
    <p className={'col-start-1 col-end-3 pl-16 md:col-start-2 md:pl-0 mt-4 md:mt-12'}>
      <HighlightedNumber startColor={startColors[props.gradientIndex]} finishColor={finishColors[props.gradientIndex]}>{props.number}</HighlightedNumber>
      &nbsp;&nbsp;{props.after}
    </p>
  </Fragment>
}

export default function ImSection() {
  return <div className={'grid grid-cols-[1fr,0] md:grid-cols-[1fr,1.5fr] text-2xl sm:text-3xl md:text-4xl lg:text-5xl'}>
    <p className={'text-5xl sm:text-7xl italic col-span-2 text-center md:text-left'}>I&apos;m.....</p>

    <ImLine before={'Managing'}
            number={8}
            after={'Apps and Websites'}
            gradientIndex={0}/>

    <ImLine before={'Maintaining'}
            number={4}
            after={'Open Source Libraries'}
            gradientIndex={1}/>

    <ImLine before={'Contributing to'}
            number={12}
            after={'Open Source Projects'}
            gradientIndex={2}/>

    <ImLine before={'Administering'}
            number={4}
            after={'Linux Servers'}
            gradientIndex={3}/>
  </div>
}
