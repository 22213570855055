import {atom, useAtom} from 'jotai'
import Divider from '../../../../components/Divider'
import {NoSSR} from '../../../../components/NoSSR'
import TagsCylinder from './TagsCylinder'
import {h, RefObject} from 'preact'
import {useEffect, useRef} from 'preact/compat'
import {useWindowSize} from 'react-use'
import ContributionWaterFall from './ContributionWaterFall'

const contributionsTitleRefState = atom<RefObject<HTMLDivElement>>({current: null})

export {contributionsTitleRefState}

function Content() {
  const {width} = useWindowSize()
  return width < 1000 ? <ContributionWaterFall/> : <TagsCylinder/>
}

export default function ContributionsSection() {
  const titleRef = useRef<HTMLDivElement>(null)
  const [, setTitleRef] = useAtom(contributionsTitleRefState)
  useEffect(() => {
    setTitleRef(titleRef)
  }, [titleRef, setTitleRef])

  return <div className={'px-8 md:px-16 2xl:pl-80 w-full flex flex-col items-center'}>
    <Divider id={'contributions'} ref={titleRef} text={'Contributions'} spacing={'mt-16'} gradientColorI={2}/>
    <NoSSR>
      <Content/>
    </NoSSR>
  </div>
}