import {taProjectCardRefState} from '../projects/ta/TeachAssistProjectCard'
import {finishColors, startColors, useRefScrollProgress} from '../../../utils'
import {m, useTransform, useViewportScroll} from 'framer-motion'
import {navBarSelectState, NavTitles} from './NavBar'
import {useAtomValue} from 'jotai/utils'
import {h} from 'preact'

export default function TopBar(props: { titles: NavTitles }) {
  const taProjectCardRef = useAtomValue(taProjectCardRefState)
  const {start, end} = useRefScrollProgress(taProjectCardRef, 0.25)
  const {scrollYProgress} = useViewportScroll()
  const transform = useTransform(useTransform(scrollYProgress, [start, end], [-2, 0]), percent => `translateY(${percent * 100}%)`)
  const selectedI = useAtomValue(navBarSelectState)

  return <m.div
    className={'bg-white shadow-md fixed top-0 left-0 right-0 h-12 z-50'}
    style={{transform}}>
    <div className={'w-full h-full relative'}>
      {props.titles.map(({text}, i) =>
        <m.span
          key={text}
          className={'gradient-text text-2xl font-bold absolute left-1/2 top-1/2 text-center whitespace-nowrap'}
          style={{
            backgroundImage: `linear-gradient(to right, ${startColors[i]} 0%, ${finishColors[i]} 100%)`,
          }}
          initial={false}
          animate={selectedI === i ? {
            opacity: 1,
            translateY: '-55%',
            translateX: '-50%',
          } : {
            opacity: 0,
            translateY: selectedI > i ? '-105%' : '-5%',
            translateX: '-50%',
          }}
          transition={{
            type: 'tween',
            ease: 'easeInOut',
          }}>
          {text}
        </m.span>)}
    </div>

  </m.div>
}