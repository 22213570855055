import {PropsWithClassName} from '../../../../utils'
import {ContributionData, contributionsData, favouriteContributionsData} from './contributionsData'
import {prLogo} from '../../../../icons/icons'
import { h } from 'preact'
import {useWindowSize} from 'react-use'
import Image from '../../../../components/Image'

function ContributionCard(props: PropsWithClassName<{ data: ContributionData }>) {
  return <a
    key={props.data.href} href={props.data.href} target={'_blank'} rel={'noreferrer'}
    className={`flex flex-col items-center text-center bg-gray-150 rounded-lg p-2 cursor-alias active:brightness-90 active:scale-95 ${props.className}`}>
    <div className={'w-12 h-12 bg-gray-300 rounded-full overflow-hidden'}>
      <Image src={props.data.image ?? prLogo}/>
    </div>
    <p className={'text-gray-600 text-sm mt-0.5 break-words w-full'}>
      {props.data.projectName}
      #{props.data.prNumber}
    </p>
    <p className={'text-xl group-hover:underline decoration-gray-450 break-words w-full'}>
      {props.data.prName}
    </p>
  </a>
}

export default function ContributionWaterFall() {
  const {width} = useWindowSize()
  const half = Math.ceil(contributionsData.length / 2);
  return width > 500 ? <div className={'flex items-start justify-between max-w-[46rem] mt-12'}>
    <div className={'flex flex-col shrink-0 w-[calc(50%-0.5rem)]'}>
      {contributionsData.slice(0,half).map(data => <ContributionCard key={data.href} data={data} className={'mt-4'}/>)}
    </div>
    <div className={'flex flex-col shrink-0 w-[calc(50%-0.5rem)]'}>
      {contributionsData.slice(-half).map(data => <ContributionCard key={data.href} data={data} className={'mt-4'}/>)}
    </div>
  </div> : <div className={'mt-12'}>
    {favouriteContributionsData.map(data => <ContributionCard key={data.href} data={data} className={'mt-4 -mx-4'}/>)}
  </div>
}