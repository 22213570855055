import {equatableLogo, igniterLogo, ktorLogo, mkdocsMaterialLogo, mlibinTALogo, remapyLogo} from '../../../../icons/icons'

export type ContributionData = {
  href: string,
  image?: string,
  projectName: string,
  prName: string,
  prNumber: number,
}

export const contributionsData: ContributionData[] = [
  {
    href: 'https://github.com/ktorio/ktor/pull/2285',
    image: ktorLogo,
    projectName: 'Ktor',
    prName: 'Fix CIO client tunneling https through http proxy',
    prNumber: 2285,
  },
  {
    href: 'https://github.com/timvink/mkdocs-git-revision-date-localized-plugin/pull/50',
    projectName: 'mkdocs-git-revision-date-localized-plugin',
    prName: 'Add {{git_creation_date_localized}}',
    prNumber: 50,
  },
  {
    href: 'https://github.com/squidfunk/mkdocs-material/pull/2494',
    image: mkdocsMaterialLogo,
    projectName: 'mkdocs-material',
    prName: 'Fix buggy header shadow',
    prNumber: 2494,
  },
  {
    href: 'https://github.com/squidfunk/mkdocs-material/pull/2487',
    image: mkdocsMaterialLogo,
    projectName: 'mkdocs-material',
    prName: 'Fix disqus breaks when instant loading enabled',
    prNumber: 2487,
  },
  {
    href: 'https://github.com/felangel/equatable/pull/97',
    image: equatableLogo,
    projectName: 'equatable',
    prName: 'Fix: stringify instance with long properties',
    prNumber: 97,
  },
  {
    href: 'https://github.com/felangel/equatable/pull/93',
    image: equatableLogo,
    projectName: 'equatable',
    prName: 'Fix: Stringify null properties as "null" instead of ""',
    prNumber: 93,
  },
  {
    href: 'https://github.com/peerdavid/remapy/pull/18',
    image: remapyLogo,
    projectName: 'remapy',
    prName: 'Fix annotation rendering on cropped pdf & add testcases',
    prNumber: 18,
  },
  {
    href: 'https://github.com/jeroentrappers/flutter_privacy_screen/pull/1',
    projectName: 'flutter_privacy_screen',
    prName: 'Fix error with new swift version',
    prNumber: 1,
  },
  {
    href: 'https://github.com/Tyrrrz/DiscordChatExporter/pull/379',
    projectName: 'DiscordChatExporter',
    prName: 'Add AUR package to README',
    prNumber: 379,
  },
  {
    href: 'https://github.com/mhgolkar/Weasel/pull/1',
    projectName: 'Weasel',
    prName: 'Make light theme white',
    prNumber: 1,
  },
  {
    href: 'https://github.com/MisterTea/EternalTerminal/pull/320',
    projectName: 'EternalTerminal',
    prName: 'Add installation instructions for CentOS 8',
    prNumber: 320,
  },
  {
    href: 'https://github.com/ShayBox/Mon2Cam/pull/16',
    projectName: 'Mon2Cam',
    prName: 'Make help & error message more clear',
    prNumber: 16,
  },
  {
    href: 'https://github.com/PlaceholderAPI/Player-Expansion/pull/29',
    projectName: 'Player-Expansion',
    prName: 'Add more placeholders',
    prNumber: 29,
  },
  {
    href: 'https://github.com/leachim6/hello-world/pull/728',
    projectName: 'hello-world',
    prName: 'Add Dongbei programming language',
    prNumber: 728,
  },
  {
    href: 'https://github.com/milbin/Teach-Assist-Android/pull/4',
    image: mlibinTALogo,
    projectName: 'Teach-Assist-Android',
    prName: 'Minor UI fixes',
    prNumber: 4,
  },
  {
    href: 'https://github.com/trojan-gfw/igniter/pull/56',
    image: igniterLogo,
    projectName: 'igniter',
    prName: 'Add a "Test Connection" button to the main activity',
    prNumber: 56,
  },
  {
    href: 'https://github.com/milbin/Teach-Assist-Android/pull/3',
    image: mlibinTALogo,
    projectName: 'Teach-Assist-Android',
    prName: 'UI fixes & performance improvements',
    prNumber: 3,
  },
  {
    href: 'https://github.com/milbin/Teach-Assist-Android/pull/2',
    image: mlibinTALogo,
    projectName: 'Teach-Assist-Android',
    prName: 'Add a "restart now" button in the dialog after changing theme',
    prNumber: 2,
  },
  {
    href: 'https://github.com/highlightjs/highlight.js/pull/3433',
    projectName: 'highlight.js',
    prName: 'New intellij-light Theme',
    prNumber: 3433,
  },
]

export const favouriteContributionsData: ContributionData[] = [0, 1, 2, 3, 4, 6, 7, 10, 12, 16]
  .map(i => contributionsData[i])