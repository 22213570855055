const coordinates = [{x: 14.995483288166215, y: 14.301717699775951}, {x: 11.38211382113821, y: 13.903410505352253}, {
  x: 7.949412827461607,
  y: 21.47124719940254,
}, {x: 6.142728093947606, y: 9.92033856111526}, {x: 7.588075880758807, y: 29.039083893452826}, {x: 8.672086720867208, y: 38.59845655962161}, {
  x: 11.562782294489612,
  y: 45.767986059248194,
}, {x: 11.924119241192411, y: 32.623848643266115}, {x: 17.70551038843722, y: 30.63231267114762}, {
  x: 21.680216802168022,
  y: 21.86955439382624,
}, {x: 23.667570009033422, y: 16.69156086631815}, {x: 15.718157181571815, y: 20.674632810555142}, {
  x: 15.176151761517614,
  y: 26.250933532486933,
}, {x: 13.008130081300814, y: 45.767986059248194}, {x: 19.15085817524842, y: 44.1747572815534}, {x: 17.163504968383016, y: 42.5815285038586}, {
  x: 18.78952122854562,
  y: 57.31889469753547,
}, {x: 19.69286359530262, y: 64.88673139158576}, {x: 24.932249322493224, y: 68.86980333582275}, {x: 23.848238482384822, y: 59.70873786407766}, {
  x: 22.04155374887082,
  y: 61.70027383619616,
}, {x: 28.72628726287263, y: 65.28503858600946}, {x: 26.377597109304425, y: 73.64948966890714}, {x: 22.22222222222222, y: 77.23425441872044}, {
  x: 24.751580849141824,
  y: 77.63256161314413,
}, {x: 22.22222222222222, y: 88.78516305700772}, {x: 20.957542908762424, y: 84.40378391834703}, {x: 22.22222222222222, y: 95.9546925566343}, {
  x: 44.986449864498645,
  y: 14.301717699775951,
}, {x: 45.16711833785004, y: 10.31864575553896}, {x: 48.96115627822945, y: 9.52203136669156}, {x: 49.50316169828365, y: 12.310181727657456}, {
  x: 45.709123757904244,
  y: 20.674632810555142,
}, {x: 46.973803071364046, y: 27.04754792133433}, {x: 48.41915085817525, y: 23.861090365944733}, {x: 49.50316169828365, y: 21.86955439382624}, {
  x: 54.74254742547425,
  y: 24.657704754792135,
}, {x: 55.28455284552846, y: 29.83569828230022}, {x: 53.116531165311656, y: 31.428927059995022}, {x: 52.75519421860885, y: 35.013691809808314}, {
  x: 54.74254742547425,
  y: 41.784914115011205,
}, {x: 57.994579945799465, y: 42.9798356982823}, {x: 41.55374887082204, y: 36.20861339307942}, {x: 40.10840108401084, y: 40.9882997261638}, {
  x: 39.56639566395664,
  y: 47.75952203136669,
}, {x: 44.26377597109305, y: 50.94597958675629}, {x: 46.070460704607044, y: 41.386606920587504}, {x: 50.22583559168925, y: 42.9798356982823}, {
  x: 51.12917795844625,
  y: 56.522280308688075,
}, {x: 47.335140018066845, y: 66.47996016928056}, {x: 48.23848238482385, y: 76.83594722429675}, {x: 52.39385727190605, y: 67.27657455812796}, {
  x: 55.28455284552846,
  y: 73.25118247448344,
}, {x: 79.31345980126467, y: 9.52203136669156}, {x: 75.88075880758808, y: 23.064475977097338}, {x: 74.43541102077688, y: 23.462783171521036}, {
  x: 69.19602529358627,
  y: 25.45431914363953,
}, {x: 77.50677506775068, y: 25.852626338063235}, {x: 75.33875338753387, y: 35.81030619865571}, {x: 77.14543812104789, y: 37.005227781926806}, {
  x: 74.97741644083108,
  y: 39.793378142892706,
}, {x: 71.54471544715447, y: 41.386606920587504}, {x: 67.75067750677508, y: 37.403534976350514}, {x: 71.90605239385727, y: 33.42046303211352}, {
  x: 63.956639566395665,
  y: 33.42046303211352,
}, {x: 60.52393857271906, y: 35.013691809808314}, {x: 65.58265582655827, y: 46.96290764251929}, {x: 66.30532971996386, y: 42.5815285038586}, {
  x: 74.61607949412827,
  y: 48.55613642021409,
}, {x: 82.38482384823848, y: 29.437391087876524}, {x: 82.02348690153568, y: 25.852626338063235}, {x: 79.31345980126467, y: 45.767986059248194}, {
  x: 78.22944896115628,
  y: 57.31889469753547,
}, {x: 73.53206865401988, y: 58.11550908638288}, {x: 76.06142728093947, y: 64.48842419716206}, {x: 86.54019873532069, y: 63.69180980831466}, {
  x: 84.91418247515809,
  y: 61.70027383619616,
}, {x: 90.33423667570008, y: 90.77669902912622}, {x: 85.63685636856368, y: 80.42071197411003}, {x: 83.64950316169828, y: 75.64102564102564}, {
  x: 77.50677506775068,
  y: 80.81901916853373,
}, {x: 76.96476964769647, y: 78.03086880756783}, {x: 86.35953026196928, y: 71.25964650236494}, {x: 83.46883468834689, y: 90.37839183470251}, {
  x: 93.04426377597109,
  y: 87.98854866816032,
}]
export default coordinates