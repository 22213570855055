import {createMedia} from '@artsy/fresnel'

const AppMedia = createMedia({
  breakpoints: {
    zero: 0,
    '440px': 440,
    xs: 500,
    '510px': 510,
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536,
  },
})

export const {MediaContextProvider, Media} = AppMedia